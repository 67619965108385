import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useQuery } from 'react-query';
import {
  useMobile,
  getDomainValue,
} from '../../utils/hooks';
import classnames from 'classnames';
import {
  Dropdown,
  DynamicDropdown,
  Loading,
  Pagination,
  Search,
} from '../../components';
import {
  HeroImage,
  Text,
} from '../../theme';
import ResourceCard from './ResourceCard';
import { RecommendedResources } from './RecommendedResources';
import colors from '../../scss/color.module.scss';
const {
  textwhite,
  secondary,
} = colors;
import './Resources.scss';
import GoToTop from '../../utils/goToTop';
import { tabTitle } from '../../utils/tabTitle';
import { goToLink } from '../../utils/goToLink';

const itemsPerPage = 16;

const getResources = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key,
    { selectedCategory,
      selectedAudienceType,
      searchText,
      selectedSortBy,
      page }] = queryKey;

  let categoryQuery = '';
  if (selectedCategory) {
    categoryQuery = `category=${selectedCategory.id}`;
  }

  let audienceQuery = '';
  if (selectedAudienceType) {
    audienceQuery = `&audience_type=[${selectedAudienceType.id}]`;
  }

  let searchTextQuery = '';
  if (searchText) {
    searchTextQuery = `&text=${searchText}`;
  }

  let pageQuery = `&page=${page}`;

  let sortByQuery = '';
  if (selectedSortBy) {
    sortByQuery = `&sort_by=${selectedSortBy.value}`;
    if (selectedSortBy.value == '7') {
      let favorites = null;
      if (getDomainValue('Resource') != null) {
        favorites = Object.keys(getDomainValue('Resource'));
        pageQuery = `&page=1`;
      }
      sortByQuery = `${sortByQuery}&favorites=${favorites}`;
    }
  }

  const baseURL = `${process.env.REACT_APP_API_ROOT}/api/assets/resources/search/`;
  const { data } = await axios.get(
    `${baseURL}?${categoryQuery}${audienceQuery}${searchTextQuery}${sortByQuery}${pageQuery}`);

  return data;
};

const sortByConstants = {
  RECOMMENDED: '0',
  DATE_ADDED: '1',
  NAME_A_TO_Z: '2',
  NAME_Z_TO_A: '3',
  CONTENT_TYPE: '4',
  ORGANIZATION_A_TO_Z: '5',
  ORGANIZATION_Z_TO_A: '6',
  FAVORITES: '7',
};

const sortByOptions = [
  { value: sortByConstants.RECOMMENDED, label: 'Sort:  Recommended' },
  { value: sortByConstants.DATE_ADDED, label: 'Sort:  Date added (newest)' },
  { value: sortByConstants.NAME_A_TO_Z, label: 'Sort:  Name (a-z)' },
  { value: sortByConstants.NAME_Z_TO_A, label: 'Sort:  Name (z-a)' },
  { value: sortByConstants.CONTENT_TYPE, label: 'Sort:  Content type' },
  { value: sortByConstants.ORGANIZATION_A_TO_Z, label: 'Sort:  Organization (a-z)' },
  { value: sortByConstants.ORGANIZATION_Z_TO_A, label: 'Sort:  Organization (z-a)' },
  { value: sortByConstants.FAVORITES, label: 'Sort:  Favorited resources' },
];

export default function Resources () {
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState();
  const [totalCounts, setTotalCounts] = useState(0);
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAudienceType, setSelectedAudienceType] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [modal, setModal] = useState(null);
  const { isMobile, isTablet } = useMobile();
  const recommendedResourcesModal = useRef();
  const scrollToPagination = useRef();
  const isMounted = useRef(false);
  tabTitle('Resources - Find Recovery Housing Now');

  useEffect(() => {
    setModal(
      new Modal(recommendedResourcesModal.current));
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (page > prevPage) {
        window.scrollTo(0, 0);
      } else {
        scrollToPagination.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const {
    isSuccess,
    error,
    data,
  } =
    useQuery(
      ['resourceData',
        { selectedCategory, selectedAudienceType, searchText, selectedSortBy, page },
      ],
      getResources,
      {
        onSuccess: (data) => {
          if (data.count != totalCounts) {
            setTotalCounts(data.count);
            if (selectedSortBy && selectedSortBy.value == sortByConstants.FAVORITES) {
              setPage(1);
            }
          }
        },
      });

  if (error) return 'An error has occurred: ' + error.message;

  const onNextClick = () => {
    setPrevPage(page);
    setPage(page + 1);
  };

  const onPreviousClick = () => {
    setPrevPage(page);
    setPage(Math.max(page - 1, 1));
  };

  const onSubmitSearchText = (data) => {
    setPage(1);
    setSearchText(data.searchText);
  };

  return (
    <>
      <header>
        <HeroImage
          height={isMobile ? '200px' : '160px'}
          className='d-flex flex-column justify-content-center'
          image="heroimage">
          <span className="mt-4 text-center mb-3">
            <Text XX_large white shadow>
              Resources
            </Text>
          </span>
          <div className="col d-flex justify-content-center" >
            <Text large white shadow className='text-center col col-md-8 col-md-8 mx-2 mx-md-0 mb-5'>
              These digital resources have been sourced from a variety of state and national organizations and
              are intended to educate and inform a variety of audiences on topics
              related to recovery and recovery housing.
            </Text>
          </div>
        </HeroImage>
        <Text className="text-center bg-banner py-2 mb-4">
          View more resources at the&nbsp;&nbsp;
          <a href='https://www.recovery-housing.org/training/'
            className="link-underline"
            target="_blank"
            rel="noreferrer">
            National Learning Management System
          </a>
        </Text>
        <div className="container-xxl">
          <div className='row my-4 mx-md-4'>
            <div className='mb-3 col-sm-12'>
              <Search
                placeholder='Search resource names, types and keywords'
                handleSubmitSearchText={onSubmitSearchText}
                setSearchText={setSearchText}
              />
            </div>
            <div className='col-sm-12'>
              <div className="row row-cols-xs-1 row-cols-sm-3 row-cols-md-4 px-3 px-md-0">
                <div className='category col-6 col-md-3 py-3 py-md-0'>
                  <DynamicDropdown
                    endpoint='assets/categories'
                    updateSelection={(e) => { setPage(1); setSelectedCategory(e); }}
                    isClearable={true}
                    instanceId='categoryType'
                    placeholder='Category'
                    menuWidth='200%'
                    mobileStyle={true}
                    dropdownColor={'blue'}
                    placeHolderStyle={{
                      color: textwhite,
                      fontSize: '16px',
                    }}
                  />
                </div>
                <div className={classnames('audience',
                  isMobile ? 'order-first' : '',
                  'col-12',
                  'col-md-3',
                  'col-lg-3',
                  'py-2',
                  'py-md-0')} >
                  <DynamicDropdown
                    endpoint='assets/audience_types'
                    updateSelection={(e) => { setPage(1); setSelectedAudienceType(e); }}
                    isClearable={true}
                    instanceId='audienceType'
                    placeholder='Audience Type'
                    menuWidth={isMobile ? '100%' : '210%'}
                    mobileStyle={true}
                    dropdownColor={'blue'}
                    placeHolderStyle={{
                      color: textwhite,
                      fontSize: '16px',
                    }}
                  />
                </div>

                {isMobile ?
                  <div className='sort col-6 col-md-3 py-3 p-md-0'>
                    <Dropdown
                      instanceId='sort_by'
                      options={sortByOptions}
                      placeholder='Sort'
                      menuWidth='150%'
                      updateSelection={(e) => { setPage(1); setSelectedSortBy(e); }}
                      dropdownColor='white'
                      withoutCarat={true}
                      border={true}
                      placeHolderStyle={{
                        color: secondary,
                        fontSize: '16px',
                      }}
                    />
                  </div> :
                  <div className='sort-by col-6 col-md-3 col-lg-4 p-3 p-md-0 px-md-3'>
                    <div className=''>
                      <Dropdown
                        border={true}
                        defaultValue={sortByOptions[0]}
                        instanceId='sort_by'
                        options={sortByOptions}
                        updateSelection={(e) => { setPage(1); setSelectedSortBy(e); }}
                        placeHolderStyle={{
                          fontSize: '16px',
                        }}
                      />
                    </div>
                  </div>
                }
                {isSuccess &&
                  <div className={classnames(
                    'px-0', 'py-md-2',
                    'd-sm-none',
                    'd-md-block',
                    'col-md-3',
                    'col-lg-2',
                    'ps-3',
                  )}>
                    <div className='row d-flex justify-content-end'>
                      <Pagination
                        onPreviousClick={onPreviousClick}
                        onNextClick={onNextClick}
                        currentPage={page}
                        totalCounts={totalCounts}
                        itemsPerPage={itemsPerPage}
                        placement='top'
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container-xxl">
        <div className='m-xs-0 m-sm-4'>
          {isSuccess ?
            data.count == 0 ?
              'No resources found' :
              <div className="
              row
              mx-0
              no-underline">
                {data.results.map((resource, index) => (
                  <div className={
                    classnames(
                      'mb-4',
                      'col-xs-12',
                      'col-sm-6',
                      'col-md-6',
                      'col-lg-4',
                      'col-xl-3',
                      { 'px-0': isMobile },
                    )}
                  key={`resource-${index}`}>
                    <span
                      role="link"
                      tabIndex={0}
                      style={{ cursor: 'pointer' }}
                      onKeyDown={(e) => {
                        goToLink(e, resource.asset.website ?
                          resource.asset.website :
                          resource.asset.asset_file);
                      }}
                      onClick={(e) => {
                        goToLink(e, resource.asset.website ?
                          resource.asset.website :
                          resource.asset.asset_file);
                      }}
                      rel="noreferrer noopener">
                      <ResourceCard resource={resource} />
                    </span>
                  </div>
                ))}
              </div> :
            <Loading />
          }
        </div>
        <div className='row ms-md-4 mb-4' ref={scrollToPagination}>
          {isSuccess &&
            data.count > 0 &&
            <div className='col-sm-12 col-md-3 mb-4'>
              <div className='row mx-0'>
                <Pagination
                  onPreviousClick={onPreviousClick}
                  onNextClick={onNextClick}
                  currentPage={page}
                  totalCounts={totalCounts}
                  itemsPerPage={itemsPerPage}
                />
              </div>
            </div>}
          <div className='col-sm-12 col-md-9 order-md-first'>
            <Text className={
              classnames(
                { 'text-center': isTablet },
                { 'text-start me-4': !isTablet },
              )}>
              Do you know about a resource that we don&apos;t have available here but should?&nbsp;&nbsp;
              {isTablet && <br />}
              <button
                className='btn btn-link ps-0 ps-xl-2'
                onClick={() => modal.show()}>
                {'Recommend a resource to add.'}
              </button>
            </Text>
          </div>
        </div>
      </div>
      <RecommendedResources
        refRecResources={recommendedResourcesModal}
        modal={modal}
      />
      <GoToTop />
    </>
  );
}
