import React from 'react';
import { RHInfoWindow } from './RHInfoWindow';
import { useMobile } from '../../utils/hooks';
import { useGetUserLocation } from '../../utils/hooks/useGetUserLocation';
import classnames from 'classnames';
import './MapViewInfo.scss';

export function MapViewInfo ({ houses, onHouseSelected, selectedHouseId }) {
  const { isTablet } = useMobile();
  const userLocation = useGetUserLocation();

  return (
    <div className={classnames(
      'mapview-info',
      'ps-0',
      'pe-3',
      {'mt-4': isTablet})}>
      {houses.map((house, index) => (
        <RHInfoWindow
          houseId={house.id}
          key={`map-info-${index}`}
          house={house}
          userLocation={userLocation}
          isSelectedHouse={house.id === selectedHouseId}
          setSelectedHouse={onHouseSelected}
        />
      ))}
    </div>
  );
}
