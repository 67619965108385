import React from 'react';
import { Text } from '../../../theme';
import Strings from '../../../AppData/Strings/HousesStrings';
import './SearchFilters.scss';
import { TransportationData } from '../../../AppData/GetHelpConstants/Transportation';
import { Checkbox } from '../../../components';
import { RadioBox } from '../../../components/shared/RadioBox';

export function TransportationsSearchFilters ({ searchStateCopy, setSearchStateCopy }) {

  return (
    <div className='accordion-item search-border'>
      <div className='accordion-header' id="headingSix">
        <button
          type='button'
          className="accordion-button collapsed p-0 search-format"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
          aria-expanded="false"
          aria-controls="collapseSix"
        >
          <Text bold large className='text-left mb-2 col'>
            {Strings.Houses.TRANSORTATION_OPTIONS.text}
          </Text>
        </button>
      </div>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        aria-labelledby="headingSix"
        data-bs-parent="#searchFiltersAccordion">
        <div className='row accordion-body'>
          <div className='row pe-2'>
            {TransportationData.TRANSPORTATION_OPTIONS.map((transportation, index) =>
              <Checkbox
                label={transportation.label}
                key={`${transportation.id}-${index}`}
                className={'my-2 col-12 col-lg-6'}
                onChange={(e) => {
                  const tempSearchData = { ...searchStateCopy.transportationOptions };
                  tempSearchData[transportation.id] = e.target.checked;
                  setSearchStateCopy({ ...searchStateCopy, transportationOptions: tempSearchData });
                }}
                isDefaultChecked={searchStateCopy.transportationOptions[transportation.id] || false}
              />)}
            <Text bold className='text-left my-2'>
              {Strings.Houses.PERSONAL_VEHICLE.text}
            </Text>
            {TransportationData.PERSONAL_VEHICLE.map((transportation, index) =>
              <RadioBox
                label={transportation.label}
                key={`${transportation.id}-${index}`}
                name={'personalVehicle'}
                className={'my-2 col-12 col-lg-6'}
                onChange={() => {
                  let tempSearchData = { ...searchStateCopy.transportationOptions };
                  tempSearchData.public = transportation.id;
                  setSearchStateCopy({ ...searchStateCopy, transportationOptions: tempSearchData });
                }}
                isDefaultChecked={searchStateCopy.transportationOptions.public === transportation.id || false}
                value={transportation.id}
              />)}
          </div>
        </div>
      </div>
      <hr></hr>
    </div >
  );
}
