import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'bootstrap';
import { SearchFilters } from './searchFilters/SearchFilters';
import { ViewOptions } from './BasicFilters';
import './MobileBasicFilters.scss';
import store from '../../store';
import { cloneDeep } from 'lodash';
import { initialSearchState, resetSearchState } from '../../features/searchSlice';
import { useDispatch } from 'react-redux';
import { useSearch } from '../../utils/useSearch';
import { SaveSearch } from './SaveSearch';
import { SaveSearchButton } from '../../components/shared/SaveSearchButton';
import BasicModal from '../../theme/wrappers/BasicModal';
import { getDomainValue } from '../../utils/hooks';

export function MobileBasicFilters ({ setSelectedViewType, selectedViewType }) {
  const [modal, setModal] = useState(null);
  const [searchStateCopyMobile, setSearchStateMobileCopy] = useState(
    cloneDeep(store.getState().search),
  );
  const searchFiltersModal = useRef();
  const dispatch = useDispatch();
  const { onSubmit } = useSearch();
  const [favoriteHouses, setFavoriteHouses] = useState(getDomainValue('House'));

  useEffect(() => {
    setModal(new Modal(searchFiltersModal.current));
  }, []);

  const handleClick = () => {
    setFavoriteHouses(getDomainValue('House'));
  };

  return (
    <div className="mobile-basic-filter-container row mx-2">
      <div className="col-6 border-end border-2 px-1">
        <div className="row g-2">
          <div className="col">
            <div className="row g-2">
              <div className="col p-0">
                <button
                  className="more-filters-button p-1"
                  type="button"
                  title="View Filters"
                  onClick={() => {
                    setSearchStateMobileCopy(cloneDeep(store.getState().search), modal.show());
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'sliders-h']} />
                  {'Filters'}
                </button>
              </div>
              <div className="col p-0">
                <button
                  className="more-filters-button"
                  type="button"
                  title="Clear Filters"
                  onClick={() => {
                    dispatch(resetSearchState());
                    onSubmit(initialSearchState);
                  }}
                >
                  {'Clear All'}
                </button>
              </div>
            </div>
          </div>
          <div className="saved-search-button-container col p-0 d-flex align-items-start">
            <BasicModal
              title={'Saved Searches'}
              id={'savedSearchModal'}
              label={'savedSearchModal'}
              modalSize="fullscreen"
              useCustomButton={true}
              usePadding={false}
              customButton={<SaveSearchButton onClick={handleClick}/>}
            >
              <SaveSearch favoriteHouses={favoriteHouses}/>
            </BasicModal>
          </div>
        </div>
      </div>
      <div className="col-6 d-flex justify-content-center px-0">
        <div className="row align-items-start">
          <div className="col m-0 p-0">
            {selectedViewType.value === '0' ? (
              <button
                type="button"
                onClick={() => setSelectedViewType(ViewOptions[1])}
                className="ps-2 pt-0 view-options-button"
              >
                <FontAwesomeIcon icon={['far', 'map-marker-alt']} />
                {'Map View'}
              </button>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => setSelectedViewType(ViewOptions[0])}
                  className="ps-2 pt-0 view-options-button"
                >
                  <FontAwesomeIcon icon={['fal', 'list']} />
                  {'List View'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <SearchFilters
        refSearch={searchFiltersModal}
        modal={modal}
        searchStateCopy={searchStateCopyMobile}
        setSearchStateCopy={setSearchStateMobileCopy}
      />
    </div>
  );
}
