import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import colors from '../../scss/color.module.scss';
const { primary, headerfooterbg, secondary } = colors;
import { handleFontsize } from './Text';

const handleBackground = (props) => {
  let backgroundColor = 'white';

  if (props.type == 'primary') {
    backgroundColor = primary;
  } else if (props.type == 'transparent') {
    backgroundColor = 'transparent';
  } else if (props.type == 'printBtn') {
    backgroundColor = 'transparent';
  } else {
    backgroundColor = 'white';
  }

  return backgroundColor;
};

const LinkButton = styled(Link).attrs(
  { className: 'btn' })`
  border: ${(props) => props.type == 'transparent' || props.type == 'secondary' ? 'solid' : 'none'};
  border-width: ${(props) => props.type == 'transparent' || props.type == 'secondary' ? '1px' : 'none'};
  border-color: ${(props) => props.type == 'secondary' ? secondary : 'white'};
  border-radius: ${(props) => props.radius || '5px'};
  target: ${(props) => props.target || '_blank'};
  color: ${(props) => props.type == 'primary' || props.type == 'transparent'
    ? 'white !important'
    : props.type == 'printBtn'
      ? primary 
      : headerfooterbg};
  background-color: ${(props) => handleBackground(props)};
  font-size: ${(props) => handleFontsize(props)};
  min-width: 150px;
  text-decoration: ${(props) => props.decoration || 'none'};
  width: ${(props) => props.width || ''};
  height: ${(props) => props.height || ''};
  line-height: ${(props) => props.lineheight || ''};
  opacity: 1;
  box-shadow: ${(props) => props.boxshadow || '0 0.5rem 1rem rgb(0 0 0 / 15%) !important'};
  :hover {
    background-color: ${(props) => props.type == 'primary' && secondary};
  }
`;

export { LinkButton };
