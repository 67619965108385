import React from 'react';
import { Text } from '../../../theme';
import { DynamicDropdown } from '../..';
import colors from '../../../scss/color.module.scss';
import { useGSModal } from '../../../utils/hooks/useGSModal';

export function GSDynamicDropdownQuestion ({ question }) {

  const { questionLabel } = useGSModal();

  return (
    <>
      <div key={question.name + index} className={question.name == 'primaryLanguage' ? languageQuestionVisable : ''}>
        <div className="row mb-5">
          <div className={isMobile ? 'col-12 pb-3' : 'col-6'}>
            <Text white large>{questionLabel(question)}</Text>
          </div>
          <div className={isMobile ? 'col-12 pb-3' : 'col-3'}>
            <div className="row">
              <DynamicDropdown
                endpoint='assets/audience_types'
                //updateSelection={setSelectedAudienceType}
                isClearable={true}
                instanceId='audienceType'
                placeholder='Select your role'
                placeHolderStyle={{ color: colors.textwhite, fontSize: '16px', justifyStart: true }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
