import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMobile } from '../../utils/hooks';
import colors from '../../scss/color.module.scss';
const { textwhite, textblack, secondary, white, placeholderColor, inputlines, primary } = colors;

const getColor = (color) => {
  let dropdownColor = 'transparent';
  switch (color) {
    case 'white':
      {
        dropdownColor = white;
      }
      break;
    case 'blue':
      {
        dropdownColor = primary;
      }
      break;
  }

  return dropdownColor;
};

export const RHdropdownStyles = {
  control: (provided, { selectProps: { border, isGuidedSearch, color, withoutCarat } }) => ({
    ...provided,
    background: getColor(color),
    border: border ? (withoutCarat ? `1px solid ${secondary}` : `1px solid ${inputlines}`) : 'none',
    paddingRight: '0px',
    boxShadow: 'none',
    borderLeft: isGuidedSearch && 'none',
    borderTop: isGuidedSearch && 'none',
    borderRight: isGuidedSearch && 'none',
    borderRadius: isGuidedSearch ? '0px' : '2px',
    '&:hover': {
      border: border ? '1px solid #9FBBCC' : 'none',
    },
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided, { selectProps: { withoutCarat } }) => ({
    ...provided,
    display: withoutCarat ? 'none' : 'flex',
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: white,
    color: textblack,
    textAlign: 'left',
    fontWeight: state.data === state.selectProps.value ? '600' : '400',
    '&:hover': {
      background: 'rgb(159, 187, 204, 0.2)',
    },
  }),
  singleValue: (provided, { selectProps: { placeholderStyle } }) => ({
    ...provided,
    fontWeight: '400',
    fontSize: placeholderStyle.fontSize,
    color: placeholderStyle.color ? placeholderStyle.color : textblack,
    paddingLeft: '5px',
  }),
  placeholder: (
    provided,
    {
      selectProps: { placeholderStyle, border, withoutCarat, wrapPlaceholder, color, showBadge },
    },
  ) => ({
    ...provided,
    color: placeholderStyle.color,
    fontSize: placeholderStyle.fontSize,
    fontWeight: placeholderStyle.fontWeight || '300',
    display: 'flex',
    justifyContent: (border && !withoutCarat) || placeholderStyle.justifyStart ? 'start' : 'center',
    flex: '1',
    margin: '0',
    padding: border && !withoutCarat ? '0 0 0 3px' : color === 'blue' ? '0 0 0 5px' : '0',
    overflow: 'hidden',
    whiteSpace: !wrapPlaceholder && 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: '0',
    position: showBadge ? 'inherit' : 'absolute',
    transform: showBadge ? 'translateY(8%)' : 'translateY(-50%)',
    webkitTransform: showBadge ? 'translateY(8%)' : 'translateY(-50%)',
  }),
  valueContainer: (provided, { selectProps: { border, withoutCarat, centerPlaceholderText } }) => ({
    ...provided,
    margin: '0',
    padding: border && !withoutCarat ? '0 0 0 10px' : '0',
    justifyContent: centerPlaceholderText && 'center',
  }),
  menu: (provided, { selectProps: { menuWidth, withoutCarat } }) => ({
    ...provided,
    width: menuWidth,
    zIndex: '1001',
    border: 'none',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.3)',
    right: withoutCarat ? '0' : 'auto',
  }),
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className='align-self-center'>
        <FontAwesomeIcon
          className='mt-1'
          icon={props.selectProps.menuIsOpen ? 'caret-up' : 'caret-down'}
          color={
            props.selectProps.color === 'blue' || props.selectProps.isGuidedSearch
              ? textwhite
              : placeholderColor
          }
        />
      </div>
    </components.DropdownIndicator>
  );
};

export const BadgeContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div className={`d-flex flex-md-wrap flex-lg-nowrap`}>
        <div className='d-flex align-self-center'>
          {React.Children.map(children, (child) =>
            child && [components.SingleValue].indexOf(child.type) === -1 ? child : null,
          )}
        </div>
        {props.selectProps.value && props.selectProps.value.length > 0 && (
          <span className='align-self-center badge bg-secondary p-1 rounded-1 ms-2'>
            {props.selectProps.value.length}
          </span>
        )}
      </div>
    </components.ValueContainer>
  );
};

const MultiSelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          className='form-check-input checkbox-input'
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label className='checkbox-label'>{props.label}</label>
      </components.Option>
    </div>
  );
};

export function Dropdown ({
  reference,
  options,
  updateSelection,
  instanceId,
  border = false,
  dropdownComponent = { DropdownIndicator },
  isClearable = false,
  isMulti = false,
  showBadge = false,
  isLoading = false,
  isSearchable = false,
  placeholder = '',
  styles = RHdropdownStyles,
  menuWidth = '100%',
  defaultValue = null,
  desktopStyle = false,
  mobileStyle = false,
  placeHolderStyle = {},
  isGuidedSearch = false,
  dropdownColor = 'transparent',
  centerCursor = false,
  centerPlaceholderText = false,
  withoutCarat = false,
  wrapPlaceholder = false, // disallow text wrapping for Placeholder values
  blurInputOnSelect = true, // Remove focus from the input when the user selects an option
  controlShouldRenderValue = true, // disabled selected values from showing in input bar
  value,
}) {
  const { isMobile } = useMobile();

  let customComponents = dropdownComponent;
  if (isMulti) {
    customComponents = { ...customComponents, Option: MultiSelectOption };
  }

  if (showBadge) {
    customComponents = { ...customComponents, ValueContainer: BadgeContainer };
  }

  let defaultPlaceHolderStyle = {
    color: placeholderColor,
    fontSize: '14px',
    justifyStart: false,
    ...placeHolderStyle,
  };

  return (
    <Select
      className='always-display-scrollbar'
      ref={reference}
      allowSelectAll={true}
      border={border}
      components={customComponents}
      closeMenuOnSelect={isMulti ? false : true}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      hideSelectedOptions={false}
      instanceId={instanceId}
      isClearable={isClearable}
      isMulti={isMulti}
      showBadge={showBadge}
      blurInputOnSelect={blurInputOnSelect}
      wrapPlaceholder={wrapPlaceholder}
      isLoading={isLoading}
      isSearchable={isSearchable}
      menuWidth={menuWidth}
      onChange={updateSelection}
      options={options}
      placeholder={placeholder}
      styles={styles}
      isMobile={desktopStyle ? false : mobileStyle ? true : isMobile}
      placeholderStyle={defaultPlaceHolderStyle}
      isGuidedSearch={isGuidedSearch}
      controlShouldRenderValue={controlShouldRenderValue}
      color={dropdownColor}
      withoutCarat={withoutCarat}
      centerCursor={centerCursor}
      centerPlaceholderText={centerPlaceholderText}
      value={value}
    />
  );
}
