import styled from 'styled-components';
import font from '../../scss/font.module.scss';
import colors from '../../scss/color.module.scss';
const {
  darkgreen,
  textwhite,
  textblack,
  textgray,
  textlink,
  headerfooterbg,
  error,
} = colors;

const truncate = () => `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const handleFontsize = (props) => {
  let fontSize = font.regular;  //14px

  if (props.very_large) {
    fontSize = font.very_large;  //40px
  } else if (props.XXX_large) {
    fontSize = font.XXX_large;  //30px
  } else if (props.XX_large) {
    fontSize = font.XX_large;  //24px
  } else if (props.extra_large) {
    fontSize = font.extra_large;  //20px
  } else if (props.large) {
    fontSize = font.large;  //16px
  } else if (props.regular) {
    fontSize = font.regular;  //14px
  } else if (props.small) {
    fontSize = font.small;  //12px
  } else if (props.extra_small) {
    fontSize = font.extra_small;  //10px
  }

  return fontSize;
};

const handleColor = (props) => {
  let color = textblack;

  if (props.white) {
    color = textwhite;
  } else if (props.gray) {      //#697588
    color = textgray;
  } else if (props.green) {     //#008859
    color = darkgreen;
  } else if (props.darkBlue) {  //#004266
    color = headerfooterbg;
  } else if (props.textlink) {  //#00588F
    color = textlink;
  } else if (props.error) {  //#E5253E
    color = error;
  }

  return color;
};

const Text = styled.div`
  color: ${(props) => handleColor(props)};
  font-size: ${(props) => handleFontsize(props)};
  font-style: ${(props) => props.italic ? 'italic' : 'normal'};
  font-weight: ${(props) => props.bold ? '600' : props.extraBold ? '700' : '400'};
  line-height: ${(props) => props.lineHeight || '1.5em'};
  text-decoration: ${(props) => props.underline ? 'underline' : 'none'};
  text-shadow: ${(props) => props.shadow ? '1px 1px 4px black' : 'none'};
  ${(props) => props.ellipsis ? truncate() : ''};
`;

export { Text, handleFontsize };
