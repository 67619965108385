import House from '../AppData/img/House_single_generic.jpg';
import Registry from '../AppData/img/About_Registry.jpg';
import Resources from '../AppData/img/About_Resources.jpg';
import TechAssist from '../AppData/img/About_TechAssist.jpg';
import FindHelpNow from '../AppData/img/About_FHN_logo.png';
import FletcherGroup from '../AppData/img/About_FLETCHER_GROUP_logo.png';
import GetHelp from '../AppData/img/About_GET_HELP_logo.png';
import KHRN from '../AppData/img/About_KRHN_logo.png';
import heroimage from '../AppData/img/heroimage.jpg';
import glossaryHero from '../AppData/img/Glossary_Hero.jpg';
import housesHero from '../AppData/img/Houses_Hero.jpg';
import NoPhoto from '../AppData/img/no_photo.png';
import MoreFilters from '../AppData/img/MoreFilters.png';
import MoreFilters_Mobile from '../AppData/img/MoreFilters_mobile.png';
import heroColor from '../AppData/img/hero-colors.png';
import logoBlues from '../AppData/img/logo-blues.svg';
import logoWhite from '../AppData/img/logo-white.svg';

const images = {
  House,
  Registry,
  Resources,
  TechAssist,
  heroimage,
  glossaryHero,
  housesHero,
  NoPhoto,
  MoreFilters,
  MoreFilters_Mobile,
  heroColor,
  logoBlues,
  logoWhite,
  FindHelpNow,
  FletcherGroup,
  GetHelp,
  KHRN,
};

function getImageByKey (key) {
  return images[key];
}

export default getImageByKey;
