import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../scss/color.module.scss';
const {
  lightgreen,
  error,
  favorites,
} = colors;
import classnames from 'classnames';
import { Text } from '../../theme';
import { HouseStatusConstants, HouseStatusData } from '../../AppData/GetHelpConstants/HouseStatus';

export function StatusIndicator ({
  status,
  type,
  size ,
  // ----- Commented out for Launch -----
  // Make sure to update BedTypes as well when commenting back in
  updatedAt,
}) {
  let color;
  const findStatus = (element) => element.id == status;
  let text = HouseStatusData.find(findStatus).label;

  switch (status) {
    case HouseStatusConstants.UNKNOWN:
      color = error;
      break;
    case HouseStatusConstants.AVAILABLE:
      color = lightgreen;
      break;
    case HouseStatusConstants.UNAVAILABLE:
      color = error;
      break;
    case HouseStatusConstants.CALL_FOR_AVAILABILITY:
      color = favorites;
      break;
  }
  const formattedDate = new Date(updatedAt).toLocaleDateString('en-US');

  return (
    <>
      {status != null && status !== HouseStatusConstants.UNKNOWN &&
        <>
          {type === 'inline' ?
            <>
              <Text large className="pe-1">
                <FontAwesomeIcon icon={['fas', 'circle']} color={color} />
              </Text>
              <Text
                large
                bold
                className='pe-2'>{text}</Text>
              <Text large gray>{updatedAt && `(as of ${formattedDate})`}</Text>

            </>
            :
            <>
              <div className={classnames(
                'flex-row',
                'd-flex',
                { 'text-start justify-content-start': type === 'leftJustify' },
                { 'text-end justify-content-end': type === 'rightJustify' })}>
                <Text
                  large={size !== 'small'}
                  small={size === 'small'}
                  className="pe-1">
                  <FontAwesomeIcon icon={['fas', 'circle']} color={color} />
                </Text>
                <div className=''>
                  <Text
                    large={size != 'small'}
                    small={size == 'small'}
                    bold
                    className='pe-2' >
                    {text}
                  </Text>
                  <Text
                    large={size != 'small'}
                    small={size == 'small'}
                    gray >
                    {updatedAt && `(as of ${formattedDate})`}
                  </Text>
                </div>
              </div>
            </>
          }
        </>}
    </>
  );
}
