export const ServicesConstants = {
  TWELVE_STEP_AA: '12_STEP_AA',
  TWELVE_STEP_NA: '12_STEP_NA',
  TWELVE_STEP_MA: '12_STEP_MA',
  TWELVE_STEP_CA: '12_STEP_CA',
  TWELVE_STEP_AL: '12_STEP_AL',
  TWELVE_STEP_CELEBRATE: '12_STEP_CELEBRATE',
  SA_CHILDCARE: 'SA_CHILDCARE',
  RES_DETOX: 'RES_DETOX',
  SA_EDUCATION_SUPPORT: 'SA_EDUCATION_SUPPORT',
  SA_EMPLOYMENT_SUPPORT: 'SA_EMPLOYMENT_SUPPORT',
  SA_CLERGY: 'SA_CLERGY',
  SA_LEGAL: 'SA_LEGAL',
  SA_LIFE_SKILLS: 'SA_LIFE_SKILLS',
  SA_PEER_SUPPORT: 'SA_PEER_SUPPORT',
  SA_READING_WRITING: 'SA_READING_WRITING',
  PRGM_SEX_OFFENDER: 'PRGM_SEX_OFFENDER',
  PRGM_CLOTHING: 'PRGM_CLOTHING',
  PRGM_SMART: 'PRGM_SMART',
  PRGM_RATIONAL: 'PRGM_RATIONAL',
  PRGM_WOMEN_SOBRIETY: 'PRGM_WOMEN_SOBRIETY',
  PRGM_MODERATION: 'PRGM_MODERATION',
  PRGM_LIFERING : 'PRGM_LIFERING',
  TA_CLINICAL_THERAPY: 'TA_CLINICAL_THERAPY',
  TA_COMPLEMENTARY_THERAPIES: 'TA_COMPLEMENTARY_THERAPIES',
  TA_INTERPERSONAL_THERAPY: 'TA_INTERPERSONAL_THERAPY',
  TA_MEDICAL_SUPERVISION: 'TA_MEDICAL_SUPERVISION',
  TA_RECREATIONAL_THERAPIES : 'TA_RECREATIONAL_THERAPIES',
  TA_SUPPORT_GROUPS: 'TA_SUPPORT_GROUPS',
  TA_THERAPEUTIC_COMMUNITY: 'TA_THERAPEUTIC_COMMUNITY',
  TA_OTHER_MENTAL: 'TA_OTHER_MENTAL',
  TA_IPT: 'TA_IPT',
  TA_COUPLES_FAM: 'TA_COUPLES_FAM',
  TA_GRP_THERAPY: 'TA_GRP_THERAPY',
  TA_CBT : 'TA_CBT',
  TA_DBT : 'TA_DBT',
};

export const ServicesData = [
  {
    id: ServicesConstants.TWELVE_STEP_AA,
    label: 'Alcoholics Anonymous',
    service_id: 622,
  },
  {
    id: ServicesConstants.TWELVE_STEP_NA,
    label: 'Narcotics Anonymous',
    service_id: 623,
  },
  {
    id: ServicesConstants.TWELVE_STEP_MA,
    label: 'Marijuana Anonymous',
    service_id: 625,
  },
  {
    id: ServicesConstants.TWELVE_STEP_CA,
    label: 'Cocaine Anonymous',
    service_id: 627,
  },
  {
    id: ServicesConstants.TWELVE_STEP_AL,
    label: 'Al-Anon',
    service_id: 629,
  },
  {
    id: ServicesConstants.TWELVE_STEP_CELEBRATE,
    label: 'Celebrate Recovery',
    service_id: 799,
  },
  {
    id: ServicesConstants.SA_CHILDCARE,
    label: 'Childcare',
    service_id: 788,
  },
  {
    id: ServicesConstants.RES_DETOX,
    label: 'Medical Withdrawal Management (Detox)',
    service_id: 645,
  },
  {
    id: ServicesConstants.SA_EDUCATION_SUPPORT,
    label: 'Education support',
    service_id: 790,
  },
  {
    id: ServicesConstants.SA_EMPLOYMENT_SUPPORT,
    label: 'Employment support',
    service_id: 791,
  },
  {
    id: ServicesConstants.SA_CLERGY,
    label: 'Faith-based recovery program',
    service_id: 686,
  },
  {
    id: ServicesConstants.SA_LEGAL,
    label: 'Legal services',
    service_id: 333,
  },
  {
    id: ServicesConstants.SA_LIFE_SKILLS,
    label: 'Life and social skills',
    service_id: 408,
  },
  {
    id: ServicesConstants.SA_PEER_SUPPORT,
    label: 'Peer support services',
    service_id: 670,
  },
  {
    id: ServicesConstants.SA_READING_WRITING,
    label: 'Reading and writing support',
    service_id: 826,
  },
  {
    id: ServicesConstants.PRGM_SEX_OFFENDER,
    label: 'Probation Approved Sex Offender Program',
    service_id: 363,
  },
  {
    id: ServicesConstants.PRGM_CLOTHING,
    label: 'Clothing Program',
    service_id: 666,
  },
  {
    id: ServicesConstants.PRGM_SMART,
    label: 'SMART recovery',
    service_id: 771,
  },
  {
    id: ServicesConstants.PRGM_RATIONAL,
    label: 'Rational Recovery',
    service_id: 772,
  },
  {
    id: ServicesConstants.PRGM_WOMEN_SOBRIETY,
    label: 'Women for Sobriety',
    service_id: 773,
  },
  {
    id: ServicesConstants.PRGM_MODERATION,
    label: 'Moderation Management',
    service_id: 774,
  },
  {
    id: ServicesConstants.PRGM_LIFERING,
    label: 'LifeRing Secular Recovery',
    service_id: 775,
  },
  {
    id: ServicesConstants.TA_CLINICAL_THERAPY,
    label: 'Clinical group therapy',
    service_id: 789,
  },
  {
    id: ServicesConstants.TA_COMPLEMENTARY_THERAPIES,
    label: 'Complementary therapies (e.g., yoga or meditation)',
    service_id: 792,
  },
  {
    id: ServicesConstants.TA_INTERPERSONAL_THERAPY,
    label: 'Interpersonal therapy',
    service_id: 793,
  },
  {
    id: ServicesConstants.TA_MEDICAL_SUPERVISION,
    label: 'Medical supervision',
    service_id: 794,
  },
  {
    id: ServicesConstants.TA_RECREATIONAL_THERAPIES,
    label: 'Recreational therapies',
    service_id: 795,
  },
  {
    id: ServicesConstants.TA_SUPPORT_GROUPS,
    label: 'Support groups',
    service_id: 796,
  },
  {
    id: ServicesConstants.TA_THERAPEUTIC_COMMUNITY,
    label: 'Therapeutic community',
    service_id: 797,
  },
  {
    id: ServicesConstants.TA_OTHER_MENTAL,
    label: 'Other Mental health services',
    service_id: 798,
  },
  {
    id: ServicesConstants.TA_IPT,
    label: 'Individual Psychotherapy / Counseling',
    service_id: 151,
  },
  {
    id: ServicesConstants.TA_COUPLES_FAM,
    label: 'Couples / Family Therapy',
    service_id: 152,
  },
  {
    id: ServicesConstants.TA_GRP_THERAPY,
    label: 'Group Therapy',
    service_id: 153,
  },
  {
    id: ServicesConstants.TA_CBT,
    label: 'Trauma Informed Cognitive Behavioral Therapy (CBT)',
    service_id: 154,
  },
  {
    id: ServicesConstants.TA_DBT,
    label: 'Dialectical Behavior Therapy (DBT)',
    service_id: 155,
  },
];

