import React from 'react';
import Strings from '../../AppData/Strings/AboutUsStrings.json';
import {
  CenteredWithMargins,
  Text,
} from '../../theme';
import AboutUsMidSec from './AboutUsMidSec';
import LinkSec from './LinkSec';
import getImageByKey from '../../utils/getImages';
import GoToTop from '../../utils/goToTop';
import { tabTitle } from '../../utils/tabTitle';

export default function AboutUs () {
  tabTitle('About Us - Find Recovery Housing Now');

  return (
    <div className="container-xxl">
      <img
        src={getImageByKey('House')}
        alt="About Us"
        className="img-fluid d-md-none" />
      <div className="my-5 text-center">
        <CenteredWithMargins className="pt-2">
          <Text XXX_large bold>
            {Strings.ABOUT_WEBSITE.label}
          </Text>
        </CenteredWithMargins>
        <CenteredWithMargins className="py-3">
          <Text extra_large bold italic gray>
            {Strings.ABOUT_WEBSITE.subHeader}
          </Text>
        </CenteredWithMargins>
        <Text className="text-center mb-3" large>
          <div className="mb-3">
            {Strings.ABOUT_WEBSITE.PARAGRAPHS[0].text}
            <a href={Strings.ADDITIONAL_PAGES[0].LINK.route}
              target="_blank"
              rel="noreferrer">Kentucky Recovery Housing Network</a>
            {', '}
            <a href={Strings.ADDITIONAL_PAGES[2].LINK.route} target="_blank" rel="noreferrer">Get Help</a>
            {', and the '}
            <a href={Strings.ADDITIONAL_PAGES[1].LINK.route} target="_blank" rel="noreferrer">Fletcher Group</a>
            {'.'}
          </div>
          <div className="mb-3">
            {Strings.ABOUT_WEBSITE.PARAGRAPHS[1].text}
          </div>
          <div className="mb-3">
            {Strings.ABOUT_WEBSITE.PARAGRAPHS[2].text}
            <a href={Strings.ABOUT_WEBSITE.PARAGRAPHS[2].LINK} target="_blank" rel="noreferrer">website</a>
            {'.'}
          </div>
          <div className="mb-3">
            {Strings.ABOUT_WEBSITE.PARAGRAPHS[3].text}
          </div>
        </Text>
      </div>
      <div className="mb-5">
        <AboutUsMidSec />
      </div>
      <CenteredWithMargins className="pt-2 text-center">
        <Text XXX_large bold>
          More about our partners
        </Text>
      </CenteredWithMargins>
      <div className="row mx-5 mb-5 d-flex align-items-center">
        {Strings.ADDITIONAL_PAGES.map((obj, index) => <LinkSec key={index} obj={obj} index={index} />)}
      </div>
      <GoToTop />
    </div>
  );
}
