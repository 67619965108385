import React from 'react';
import { useGSModal } from '../../../utils/hooks/useGSModal';
import { useMobile } from '../../../utils/hooks';
import { GSWelcome } from './GSWelcome';
import { GSResults } from './GSResults';
import { GSQuestions } from './GSQuestions';
import GuidedSearchDesktop from '../../../AppData/Strings/GuidedSearchDesktop.json';
import GuidedSearchMobile from '../../../AppData/Strings/GuidedSearchMobile.json';
import  { useSelector } from 'react-redux';

const lastDeskTopPage = GuidedSearchDesktop.DESKTOP_PAGES.length - 1;
const lastMobilePage = GuidedSearchMobile.MOBILE_PAGES.length - 1;

export default function GSMidSec () {
  const page = useSelector((state) => state.guidedSearch.utilityStates.page);
  const { changePageUp } = useGSModal();

  const { isMobile } = useMobile();

  const lastPage = () => {
    return isMobile ? lastMobilePage : lastDeskTopPage;
  };

  return (
    <div className={`${!isMobile ? 'mx-5' : ''} mb-5`}>
      {page == 0 ?
        <GSWelcome onClick={() => changePageUp(page)} /> :
        page == lastPage() ? <GSResults /> :
          <GSQuestions />}
    </div>
  );
}
