import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import './CheckoutProductDetail.scss';

export function CheckoutProductDetail ({ productDetail, checkoutSetValue }) {
  const [selected, setSelected] = useState(true);
  const { setValue } = useFormContext();

  const deleteProduct = (event) =>{
    event.preventDefault();
    setValue(`${productDetail.id}.digital`, false);
    checkoutSetValue(`${productDetail.id}.digital`, false);
    setValue(`${productDetail.id}.quantity`, '');
    checkoutSetValue(`${productDetail.id}.quantity`, '');
    setSelected(false);
    setValue(`${productDetail.id}.selected`, false);
  };

  useEffect(() => {
    setValue(`${productDetail.id}.digital`, true);
    checkoutSetValue(`${productDetail.id}.digital`, true);
    setValue(`${productDetail.id}.quantity`, '');
    checkoutSetValue(`${productDetail.id}.quantity`, '');
  }
  , [productDetail.id, setValue, checkoutSetValue]);

  return (
    <div className={classnames(
      {'d-none': !selected})}>
      <div className='row digital-product p-2 mx-0 mb-3 d-flex justify-content-between'>
        <div className='col-xs-12 col-sm-12 col-md-5 px-0 order-product-title'>
          {productDetail.title}
        </div>
        <div className='col-sm-1 col-md-1 pt-4 ps-2 delete-col'>
          <button
            onClick={deleteProduct}
            className='bg-white border-0 ps-0 pe-0'>
            <FontAwesomeIcon
              size='lg'
              color={'#004266'}
              icon={['fas', 'trash-alt']} />
          </button>
        </div>
      </div>
    </div>
  );
}
