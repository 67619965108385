import React, {useState} from 'react';
import { useMobile } from '../../utils/hooks';
import { MapViewInfo } from './MapViewInfo';
import { MapViewCard } from './MapViewCard';

export function MapView ({ houses }) {
  const [selectedHouseId, setSelectedHouseId] = useState(null);
  const { isTablet } = useMobile();

  const onHouseSelected = (houseId) => {
    setSelectedHouseId(houseId);
  };

  return (
    <>{isTablet ?
      <div className='container-xxl row mx-0 px-0'>
        <MapViewCard
          houses={houses}
          onHouseSelected={onHouseSelected}
          selectedHouseId={selectedHouseId}
        />
        <MapViewInfo
          houses={houses}
          onHouseSelected={onHouseSelected}
          selectedHouseId={selectedHouseId}
        />
      </div>
      :<div className='container-xxl row mx-0 px-0'>
        <div className='col-5 pe-0'>
          <MapViewInfo
            houses={houses}
            onHouseSelected={onHouseSelected}
            selectedHouseId={selectedHouseId}
          />
        </div>
        <div className='col-7'>
          <MapViewCard
            houses={houses}
            onHouseSelected={onHouseSelected}
            selectedHouseId={selectedHouseId}
          />
        </div>
      </div>
    }</>
  );
}
