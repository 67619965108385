export const DepositConstants = {
  DEP_DEPOSIT_REQ: 'DEP_DEPOSIT_REQ',
  DEP_FINANCIAL_ASSISTANCE: 'DEP_FINANCIAL_ASSISTANCE',
  DEP_APPLICATION_FEE: 'DEP_APPLICATION_FEE',
  DEP_WEEKLY_PAYMENTS: 'DEP_WEEKLY_PAYMENTS',
  DEP_FLEXIBLE: 'DEP_FLEXIBLE',
};

export const DepositData = [
  {
    'id': DepositConstants.DEP_DEPOSIT_REQ,
    'label': 'Deposit required',
  },
  {
    'id': DepositConstants.DEP_FINANCIAL_ASSISTANCE,
    'label': 'Financial assistance available ',
  },
  {
    'id': DepositConstants.DEP_FLEXIBLE,
    'label': 'Flexible deposit options available',
  },
  {
    'id': DepositConstants.DEP_APPLICATION_FEE,
    'label': 'Application fee required',
  },
  {
    'id': DepositConstants.DEP_WEEKLY_PAYMENTS,
    'label': 'Weekly payments accepted',
  },
];

export const DueDateConstants = {
  FLEXIBLE_PAYMENT: 'FLEXIBLE_PAYMENT',
  MOVE_IN_DAY_PAYMENT: 'MOVE_IN_DAY_PAYMENT',
};

export const DueDateData = [
  {
    'id': DueDateConstants.FLEXIBLE_PAYMENT,
    'label': 'Flexible deposit options available',
  },
  {
    'id': DueDateConstants.MOVE_IN_DAY_PAYMENT,
    'label': 'Move in day',
  },
];
