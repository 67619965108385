import React from 'react';

export function Checkbox ({ label, className, isDefaultChecked, onChange }) {

  return (
    <label className={`checkbox-label ${className}`} >
      <input
        className='form-check-input checkbox-input me-2'
        type='checkbox'
        checked={isDefaultChecked}
        onChange={onChange}
      />
      {label}
    </label>
  );
}
