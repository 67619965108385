import styled from 'styled-components';
import { handleFontsize } from './Text';
import colors from '../../scss/color.module.scss';
const { error, white } = colors;

const ErrorText = styled.span`
  color: ${error};
  background-color: ${(props) => props.bgWhite ? white : 'rgba(229,37,62,0.1)'};
  border-radius: ${'2px'};
  font-size: ${(props) => handleFontsize(props)};
  font-style: ${(props) => props.italic ? 'italic' : 'normal'};
  font-weight: ${(props) => props.bold ? '600' : '400'};
  line-height: ${(props) => props.lineHeight || '1.5em'};
  text-decoration: ${(props) => props.underline ? 'underline' : 'none'};
  text-shadow: none;
  ${(props) => props.ellipsis ? truncate() : ''};
`;

export { ErrorText };
