import React from 'react';
import './CurrencyInput.scss';

export function CurrencyInput ({onChange, value}) {
  return (
    <div className='input-group currency-input mb-3'>
      <span className='input-group-text'>$</span>
      <input
        type='number'
        className='form-control'
        onChange={onChange}
        value={value}
        min={0}
        step={0.01}
      />
    </div>
  );
}
