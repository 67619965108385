import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NARRLevelsData } from '../../AppData/GetHelpConstants/NARRLevels';
import { AccreditationsConstants } from '../../AppData/GetHelpConstants/Accreditations';
import MoreInfoLink from '../../pages/HouseDetail/MoreInfoLink';
import MoreInfoModal from './MoreInfoModal';
import MoreInfo from '../../AppData/Strings/MoreInfo.json';

export default function Certifications ({ houseInfo, hideText }) {

  const certifications = houseInfo.certifications.certs;

  const findCertification = (certifications) => {

    let certification = null;
    const NARRCertification = certifications.find((element) => element.association === AccreditationsConstants.NARR);
    if (NARRCertification && NARRCertification.status === 'VERIFIED') {
      certification = NARRCertification;
    }
    if (!certification) {
      certification = certifications.find((element) => element.association === AccreditationsConstants.OXFORD_HOUSE);
    }
    if (certification) {
      return certification;
    }
  };

  const houseCertification = findCertification(certifications);
  const certificationLevel = houseCertification?.level && NARRLevelsData.find(
    (element) => element.id == houseCertification.level);
  const certLabel = certificationLevel && certificationLevel.label;

  return (
    <>
      {houseCertification &&
        <>
          <FontAwesomeIcon icon={['fas', 'certificate']} />
          {`${houseCertification.association}-certified `}
          {certLabel && `(${certLabel})`}
          <MoreInfoLink
            icon='question-circle'
            target={MoreInfo.HOUSE_DETAILS.certification.id}
            text='What is this?'
            hideText={hideText}
          />
          <MoreInfoModal
            data={MoreInfo.HOUSE_DETAILS.certification}
          />
        </>
      }
    </>
  );
}
