import React from 'react';
import { Link } from 'react-router-dom';
import { jsonReplace } from '../../../utils/jsonLoader';
import NavDataRaw from '../../../AppData/navigation/navigation';

const NavData = jsonReplace(NavDataRaw, process.env);

export function NavTab ({ isMobile }) {
  const offcanvasProps = isMobile
    ? { 'data-bs-dismiss': 'offcanvas' }
    : {};

  return (
    <>
      {NavData.NavBar.map((obj, index) =>
        <Link
          key={index}
          className="mainNav nav-link pe-5 text-white fw-bold"
          target={obj.target}
          rel={obj.rel}
          to={obj.externallink ? { pathname: obj.link } : obj.link}
        >
          <span {...offcanvasProps}>
            {obj.label}
          </span>
        </Link>,
      )}
    </>
  );
}
