import React from 'react';
import getImageByKey from '../../../utils/getImages';
import NavDataRaw from '../../../AppData/navigation/navigation';
import { Text } from '../../../theme';
import { FooterSections } from './FooterSections';
import { jsonReplace } from '../../../utils/jsonLoader';
import { useMobile } from '../../../utils/hooks';
import classNames from 'classnames';

export function Footer () {

  const NavData = jsonReplace(NavDataRaw, process.env);
  const sites = NavData.NavBar;
  sites.unshift(NavData.Sitemap);
  const { isXLScreen } = useMobile();

  return (
    <>
      <div className="container-fluid bg-headerfooterbg pt-4 px-5 row mainFooter mx-0">
        <div className="col-md-3 col-sm-12 pb-4 ps-0 pe-xl-5">
          <img src={getImageByKey('logoBlues')}
            alt="Find Recovery Housing Logo"
            className={classNames(
              'pb-3',
              'px-md-0',
              { 'img-fluid': !isXLScreen },
              { 'w-75': isXLScreen },
            )}
          />
          <div className="pb-3">
            {NavData.Contact.stakeholders.map((stakeholder, index) =>
              <Text white
                key={index}
                bold>
                {stakeholder}
              </Text>)}
          </div>
          <Text white>
            <div>
              <a href={`tel:${NavData.Contact.phone}`} className='text-decoration-none text-white'>
                {NavData.Contact.phone}
              </a>
            </div>
            <a
              className="text-white"
              href={`https://${NavData.Contact.website}`}
              target="_blank" rel="noreferrer noopener">
              {NavData.Contact.website}
            </a>
          </Text>
        </div>
        {NavData.FooterColumns.map((obj, index) => <FooterSections key={index} obj={obj} NavBar={sites} />)}
      </div>
      <div className="bg-secondary text-center p-2">
        <Text white className="pb-2">
          {NavData.FooterDisclaimer.copyright}
        </Text>
        <Text white small>
          {NavData.FooterDisclaimer.grantInfo}
        </Text>
      </div>
    </>
  );
}
