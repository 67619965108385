import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '../../../components';
import { Text } from '../../../theme';
import Strings from '../../../AppData/Strings/HousesStrings';
import './SearchFilters.scss';
import { NARRLevelsData } from '../../../AppData/GetHelpConstants/NARRLevels';
import { StructureTypesData } from '../../../AppData/GetHelpConstants/StructureTypes';
import { Checkbox } from '../../../components';
import { AccreditationsConstants } from '../../../AppData/GetHelpConstants/Accreditations';
import { MedicationsData } from '../../../AppData/GetHelpConstants/Medications';
import { RadioBox } from '../../../components/shared/RadioBox';

export function HousingOptionsSearchFilters ({ searchStateCopy, setSearchStateCopy }) {

  return (
    <div className='accordion-item search-border'>
      <div className='accordion-header' id='headingTwo'>
        <button
          type='button'
          className='accordion-button collapsed p-0 search-format'
          data-bs-toggle='collapse'
          data-bs-target='#collapseTwo'
          aria-expanded='false'
          aria-controls='collapseTwo'
        >
          <Text bold large className='text-left mb-2 col'>
            {Strings.Houses.HOUSING_OPTIONS.text}
          </Text>
        </button>
      </div>
      <div
        id='collapseTwo'
        className='accordion-collapse collapse'
        aria-labelledby='headingTwo'
        data-bs-parent='#searchFiltersAccordion'>
        <div className='row accordion-body'>
          <div className='col-12 col-lg-6 pe-2'>
            <div className='row my-3'>
              <div className='input-group mb-3 housing-options-search'>
                <span className='input-group-text bg-white border-end-0 search-icon'>
                  <FontAwesomeIcon icon={['far', 'search']} />
                </span>
                <input
                  type='text'
                  className='form-control border-start-0 px-0'
                  placeholder={Strings.Houses.SEARCH_SPECIFIC_HOME_NAME.text}
                  aria-label={Strings.Houses.SEARCH_SPECIFIC_HOME_NAME.text}
                  aria-describedby='basic-addon2'
                  value={searchStateCopy.name || ''}
                  onChange={(e) => setSearchStateCopy({ ...searchStateCopy, name: e.target.value })}
                />
                <div className='row row-cols-2 my-3'>
                  <label
                    className='label-text'>
                    {Strings.Houses.HOUSE_TYPE.text}
                  </label>
                  <Dropdown
                    instanceId='houseTypeSearchFilter'
                    isClearable={true}
                    options={StructureTypesData}
                    placeholder={Strings.Houses.SELECT.text}
                    border={true}
                    value={StructureTypesData.find((item) => item.id === searchStateCopy.structureType) || ''}
                    updateSelection={(value) =>
                      setSearchStateCopy({ ...searchStateCopy, structureType: value ? value.id : '' })}
                  />
                </div>
              </div>

              <Text bold className='text-left my-3'>
                {Strings.Houses.MOUD.text}
              </Text>
              <Checkbox className={'mb-3'}
                label={Strings.Houses.MOUD_FRIENDLY.text}
                isDefaultChecked={searchStateCopy.moudCapable || false}
                onChange={(e) => {
                  if (e.target.checked === false) {
                    setSearchStateCopy({ ...searchStateCopy, medications: '', moudCapable: e.target.checked });
                  } else {
                    setSearchStateCopy({ ...searchStateCopy, moudCapable: e.target.checked });
                  }
                }}
              />
              {searchStateCopy.moudCapable &&
                <>
                  <Text bold>
                    MOUD medications
                  </Text>
                  {MedicationsData.map((medication, index) =>
                    <RadioBox
                      label={medication.label}
                      key={`${medication.id}_${index}`}
                      name={'MOUD_medications'}
                      className={'my-2 col-12 col-lg-6'}
                      onChange={() => {
                        let tempSearchData = { ...searchStateCopy.medications };
                        tempSearchData = medication.id;
                        setSearchStateCopy({ ...searchStateCopy, medications: tempSearchData });
                      }}
                      isDefaultChecked={searchStateCopy.medications === medication.id || ''}
                      value={medication.id}
                    />,
                  )}
                </>
              }
            </div>
            <Text bold className='text-left my-3'>
              {Strings.Houses.MISCELLANEOUS_RESIDENT_REQUIREMENTS.text}
            </Text>
            <Checkbox className={'my-3'}
              name='urineTestRequired'
              label={Strings.Houses.MANDATORY_DRUG_TESTING.text}
              isDefaultChecked={searchStateCopy.urineTestRequired || false}
              onChange={(e) => setSearchStateCopy({ ...searchStateCopy, urineTestRequired: e.target.checked })}
            />
            <Checkbox className={'my-3'}
              label={Strings.Houses.MANDATORY_HOUSE_MEETINGS.text}
              isDefaultChecked={searchStateCopy.houseMeetings || false}
              onChange={(e) => setSearchStateCopy({ ...searchStateCopy, houseMeetings: e.target.checked })}
            />
            <Checkbox className={'my-3'}
              label={Strings.Houses.SMOKING_ALLOWED.text}
              isDefaultChecked={searchStateCopy.smokingAllowed || false}
              onChange={(e) => setSearchStateCopy({ ...searchStateCopy, smokingAllowed: e.target.checked })}
            />
          </div>
          <div className='col-12 col-lg-6 ps-3'>
            {/* -------Commented out for Alpha release------- */}

            {/* <div className='row row-cols-2 my-3'>
            <label
              className='label-text'>
              {Strings.Houses.ROOM_TYPE.text}
            </label>
            <Dropdown
              instanceId='roomTypeSearchFilter'
              isClearable={true}
              options={SearchDistanceData}
              placeholder={Strings.Houses.SELECT.text}
              border={true}
            />
          </div> */}
            {/* <div className='row row-cols-2 my-3'>
            <label
              className='label-text'>
              {Strings.Houses.MIN_STAY.text}
            </label>
            <Dropdown
              instanceId='minStaySearchFilter'
              isClearable={true}
              options={SearchDistanceData}
              placeholder={Strings.Houses.SELECT.text}
              border={true}
            />
          </div> */}
            <Text bold className='text-left my-3'>
              {Strings.Houses.CERTIFICATIONS.text}
            </Text>
            <Checkbox className={'my-3'}
              label={AccreditationsConstants.NARR}
              isDefaultChecked={searchStateCopy.accreditations[AccreditationsConstants.NARR] || false}
              onChange={(e) => {
                const tempSearchData = { ...searchStateCopy.accreditations };
                tempSearchData[AccreditationsConstants.NARR] = e.target.checked;
                setSearchStateCopy({ ...searchStateCopy, accreditations: tempSearchData });
              }
              }
            />
            <Checkbox className={'my-3'}
              label={AccreditationsConstants.OXFORD_HOUSE}
              isDefaultChecked={searchStateCopy.associations[AccreditationsConstants.OXFORD_HOUSE] || false}
              onChange={(e) => {
                const tempSearchData = { ...searchStateCopy.associations };
                tempSearchData[AccreditationsConstants.OXFORD_HOUSE] = e.target.checked;
                setSearchStateCopy({ ...searchStateCopy, associations: tempSearchData });
              }
              }
            />
            <div className='row row-cols-2 my-3'>
              <label
                className='label-text'>
                {Strings.Houses.NARR_LEVEL.text}
              </label>
              <Dropdown
                instanceId='narrLevelSearchFilter'
                isClearable={true}
                options={NARRLevelsData}
                placeholder={Strings.Houses.SELECT.text}
                border={true}
                value={NARRLevelsData.find((element) => element.id === searchStateCopy.accreditationLevel) || ''}
                updateSelection={(value) =>
                  setSearchStateCopy({ ...searchStateCopy, accreditationLevel: value?.id })}
              />
            </div>
            {/* -------Commented out for Alpha release------- */}
            {/* <div className='form-check'>
            <input className='form-check-input' type='radio' name='certRadio' id='narrRadio' />
            <label className='form-check-label' htmlFor='narrRadio'>
              {Strings.Houses.NARR.text}
            </label>
          </div>
          <div className='form-check'>
            <input className='form-check-input' type='radio' name='certRadio' id='oxfordRadio' />
            <label className='form-check-label' htmlFor='oxfordRadio'>
              {Strings.Houses.OXFORD_HOUSES.text}
            </label>
          </div> */}
          </div>
        </div>
      </div>
      <hr></hr>
    </div >
  );
}
