import React from 'react';
import { useGSModal } from '../../../utils/hooks/useGSModal';
import GSMidSec from './GSMidSec';
import './GuidedSearchModal.scss';
import { useMobile } from '../../../utils/hooks';
import { CloseButton } from '../../../components';
import GuidedSearchDesktop from '../../../AppData/Strings/GuidedSearchDesktop.json';
import GuidedSearchMobile from '../../../AppData/Strings/GuidedSearchMobile.json';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function GuidedSearchModal () {

  const { onGSModalClose } = useGSModal();
  const page = useSelector((state) => state.guidedSearch.utilityStates.page);
  const modalVisible = useSelector((state) => state.guidedSearch.utilityStates.modalVisible);
  const { isMobile } = useMobile();
  const methods = useForm();

  let percentDone = page / (isMobile ?
    GuidedSearchMobile.MOBILE_PAGES.length - 1 :
    GuidedSearchDesktop.DESKTOP_PAGES.length - 1) * 100;

  return (
    <div className={`modal ${modalVisible ? 'd-block' : 'd-none'}`}
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-fullscreen w-100">
        <div className="modal-content ">
          <div className="modal-body bg-headerfooterbg">
            <div className="row mx-2 mt-3">
              <div className={`col ${!isMobile && 'ms-5'} align-self-center`}>
                {page > 0 &&
                  <div className="progress progressBG" style={{ height: '10px' }}>
                    <div
                      className="progress-bar bg-white"
                      role="progressbar"
                      style={{ width: `${percentDone}%` }}
                      aria-valuenow={{ percentDone }}
                      aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                }
              </div>
              <div className="col col-1 d-flex justify-content-end p-0">
                <CloseButton onClick={onGSModalClose} color='lite' />
              </div>
              <div className="text-white">
                <FormProvider {...methods}>
                  <GSMidSec />
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
