import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GenderData } from '../../AppData/GetHelpConstants/Gender';

export default function Gender (props) {

  const {
    genderServed,
    iconSize,
  } = props;

  let genderInfo = GenderData.find((element) => element.id == genderServed);

  return (
    <>
      {genderInfo &&
        <>
          <FontAwesomeIcon icon={genderInfo.icon} size={iconSize} />
          {genderInfo.label}
        </>
      }
    </>
  );
}
