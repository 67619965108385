import { useState, useEffect } from 'react';

const prefix = process.env.REACT_APP_TOKEN_NAME;

export function getDomainValue (domain) {
  const saved = localStorage.getItem(`${prefix}.${domain}`);

  return JSON.parse(saved);
}

function getStorageValue (domain, key) {
  const saved = getDomainValue(domain);

  if (key) {
    if (saved !== null && key in saved) {
      return saved[key];
    } else {
      return null;
    }
  } else {
    return saved;
  }
}

export const useLocalStorage = (domain, key) => {
  const [value, setValue] = useState(getStorageValue(domain, key));

  useEffect(() => {
    if (key != null && value != null) {
      const saved = { ...getDomainValue(domain)};
      saved[key] = value;
      localStorage.setItem(`${prefix}.${domain}`, JSON.stringify(saved));
    }
  }, [key, value, domain]);

  const removeItem = () => {
    const saved = getDomainValue(domain);
    if (saved !== null && key in saved) {
      delete saved[key];
    }

    if (Object.keys(saved).length === 0) {
      localStorage.removeItem(`${prefix}.${domain}`);
    } else {
      localStorage.setItem(`${prefix}.${domain}`, JSON.stringify(saved));
    }
    setValue(null);
  };

  return [value, setValue, removeItem];
};
