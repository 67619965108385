import React from 'react';
import { Text, RHLink } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function MoreInfoLink ({ icon, target, text, hideText }) {

  return (
    <>
      <Text
        textlink
        large
        className="ps-3 changeCursor"
        data-bs-toggle="modal"
        data-bs-target={`#${target}`}
        style={{ display: 'inline' }}>
        <FontAwesomeIcon icon={['fas', icon]} />
      </Text>
      {hideText ? ''
        :
        <RHLink
          to={'/'}
          data-bs-toggle="modal"
          data-bs-target={`#${target}`}
          style={{ display: 'inline' }}>
          {text}
        </RHLink>
      }
    </>
  );
}
