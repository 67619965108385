export const AmenitiesConstants = {
  AMEN_WIFI: 'AMEN_WIFI',
  AMEN_STORAGE: 'AMEN_STORAGE',
  AMEN_BATH_SHARED: 'AMEN_BATH_SHARED',
  AMEN_BATH_PRIVATE: 'AMEN_BATH_PRIVATE',
  AMEN_KITCHEN_COMMUNAL: 'AMEN_KITCHEN_COMMUNAL',
  AMEN_LAUNDRY: 'AMEN_LAUNDRY',
  AMEN_PARKING_ON_PAID: 'AMEN_PARKING_ON_PAID',
  AMEN_MEALS: 'AMEN_MEALS',
  AMEN_NARCAN: 'AMEN_NARCAN',
  AMEN_AIR_CONDITIONING: 'AMEN_AIR_CONDITIONING',
  AMEN_ANIMAL_PET: 'AMEN_ANIMAL_PET',
  AMEN_ANIMAL_SERVICE: 'AMEN_ANIMAL_SERVICE',
  AMEN_BABY_BATH: 'AMEN_BABY_BATH',
  AMEN_BABY_CHANGE: 'AMEN_BABY_CHANGE',
  AMEN_BABY_CHAIR: 'AMEN_BABY_CHAIR',
  AMEN_BABY_CRIB: 'AMEN_BABY_CRIB',
  AMEN_BABY_GATE: 'AMEN_BABY_GATE',
  AMEN_BABY_MONITOR: 'AMEN_BABY_MONITOR',
  AMEN_BACKYARD: 'AMEN_BACKYARD',
  AMEN_BOARD_GAMES: 'AMEN_BOARD_GAMES',
  AMEN_BOOKS: 'AMEN_BOOKS',
  AMEN_CARBON_ALARM: 'AMEN_CARBON_ALARM',
  AMEN_CHILD_BOOKS: 'AMEN_CHILD_BOOKS',
  AMEN_COMPUTERS: 'AMEN_COMPUTERS',
  AMEN_DINING: 'AMEN_DINING',
  AMEN_ELEVATOR: 'AMEN_ELEVATOR',
  AMEN_EQUIP_EXERCISE: 'AMEN_EQUIP_EXERCISE',
  AMEN_EQUIP_REC: 'AMEN_EQUIP_REC',
  AMEN_FIRE_PIT: 'AMEN_FIRE_PIT',
  AMEN_FURNISH_ROOM: 'AMEN_FURNISH_ROOM',
  AMEN_GRILL: 'AMEN_GRILL',
  AMEN_HEATING: 'AMEN_HEATING',
  AMEN_IN_ROOM_TV: 'AMEN_IN_ROOM_TV',
  AMEN_KITCHEN_PERSONAL: 'AMEN_KITCHEN_PERSONAL',
  AMEN_LAUNDRY_NEARBY: 'AMEN_LAUNDRY_NEARBY',
  AMEN_LINENS: 'AMEN_LINENS',
  AMEN_OUTDOOR_DINING: 'AMEN_OUTDOOR_DINING',
  AMEN_OUTDOOR_FURNITURE: 'AMEN_OUTDOOR_FURNITURE',
  AMEN_OVERNIGHT_GUEST: 'AMEN_OVERNIGHT_GUEST',
  AMEN_PARKING_FREE: 'AMEN_PARKING_FREE',
  AMEN_PARKING_OFF_PAID: 'AMEN_PARKING_OFF_PAID',
  AMEN_PARKING_STREET: 'AMEN_PARKING_STREET',
  AMEN_PATIO: 'AMEN_PATIO',
  AMEN_POOL: 'AMEN_POOL',
  AMEN_WATERFRONT: 'AMEN_WATERFRONT',
  AMEN_WORKSPACE: 'AMEN_WORKSPACE',
};

export const AmenitiesData = [
  {
    'id': AmenitiesConstants.AMEN_WIFI,
    'label': 'Wifi',
  },
  {
    'id': AmenitiesConstants.AMEN_STORAGE,
    'label': 'Personal Storage',
  },
  {
    'id': AmenitiesConstants.AMEN_BATH_SHARED,
    'label': 'Shared bathrooms',
  },
  {
    'id': AmenitiesConstants.AMEN_BATH_PRIVATE,
    'label': 'Private Bathrooms',
  },
  {
    'id': AmenitiesConstants.AMEN_KITCHEN_COMMUNAL,
    'label': 'Communal Kitchen',
  },
  {
    'id': AmenitiesConstants.AMEN_LAUNDRY,
    'label': 'Laundry',
  },
  {
    'id': AmenitiesConstants.AMEN_PARKING_ON_PAID,
    'label': 'Paid parking on premises',
  },
  {
    'id': AmenitiesConstants.AMEN_MEALS,
    'label': 'Meals Provided',
  },
  {
    'id': AmenitiesConstants.AMEN_NARCAN,
    'label': 'Naloxone/Narcan',
  },
  {
    'id': AmenitiesConstants.AMEN_AIR_CONDITIONING,
    'label': 'Air Conditioning',
  },
  {
    'id': AmenitiesConstants.AMEN_ANIMAL_PET,
    'label': 'Pets allowed',
  },
  {
    'id': AmenitiesConstants.AMEN_ANIMAL_SERVICE,
    'label': 'Service animals',
  },
  {
    'id': AmenitiesConstants.AMEN_BABY_BATH,
    'label': 'Baby bath',
  },
  {
    'id': AmenitiesConstants.AMEN_BABY_CHAIR,
    'label': 'High chair',
  },
  {
    'id': AmenitiesConstants.AMEN_BABY_CHANGE,
    'label': 'Changing table',
  },
  {
    'id': AmenitiesConstants.AMEN_BABY_CRIB,
    'label': 'Crib',
  },
  {
    'id': AmenitiesConstants.AMEN_BABY_GATE,
    'label': 'Baby safety gates',
  },
  {
    'id': AmenitiesConstants.AMEN_BABY_MONITOR,
    'label': 'Baby monitor',
  },
  {
    'id': AmenitiesConstants.AMEN_BACKYARD,
    'label': 'Backyard',
  },
  {
    'id': AmenitiesConstants.AMEN_BOARD_GAMES,
    'label': 'Board games',
  },
  {
    'id': AmenitiesConstants.AMEN_BOOKS,
    'label': 'Books and reading material',
  },
  {
    'id': AmenitiesConstants.AMEN_CARBON_ALARM,
    'label': 'Carbon monoxide alarm',
  },
  {
    'id': AmenitiesConstants.AMEN_CHILD_BOOKS,
    'label': 'Children’s books and toys',
  },
  {
    'id': AmenitiesConstants.AMEN_COMPUTERS,
    'label': 'Computers Available',
  },
  {
    'id': AmenitiesConstants.AMEN_DINING,
    'label': 'Dining Area',
  },
  {
    'id': AmenitiesConstants.AMEN_ELEVATOR,
    'label': 'Elevator',
  },
  {
    'id': AmenitiesConstants.AMEN_EQUIP_EXERCISE,
    'label': 'Exercise equipment',
  },
  {
    'id':AmenitiesConstants.AMEN_EQUIP_REC,
    'label': 'Recreational Equipment',
  },
  {
    'id': AmenitiesConstants.AMEN_FIRE_PIT,
    'label': 'Fire Pit',
  },
  {
    'id': AmenitiesConstants.AMEN_FURNISH_ROOM,
    'label': 'Furnished room',
  },
  {
    'id': AmenitiesConstants.AMEN_GRILL,
    'label': 'BBQ grill',
  },
  {
    'id': AmenitiesConstants.AMEN_HEATING,
    'label': 'Heating',
  },
  {
    'id': AmenitiesConstants.AMEN_IN_ROOM_TV,
    'label': 'In-room TV',
  },
  {
    'id': AmenitiesConstants.AMEN_KITCHEN_PERSONAL,
    'label': 'Personal Kitchen',
  },
  {
    'id': AmenitiesConstants.AMEN_LAUNDRY_NEARBY,
    'label': 'Laundromat nearby',
  },
  {
    'id': AmenitiesConstants.AMEN_LINENS,
    'label': 'Bed Linens (Sheets, Covers, Pillows)',
  },
  {
    'id': AmenitiesConstants.AMEN_OUTDOOR_DINING,
    'label': 'Outdoor dining area',
  },
  {
    'id': AmenitiesConstants.AMEN_OUTDOOR_FURNITURE,
    'label': 'Outdoor furniture',
  },
  {
    'id': AmenitiesConstants.AMEN_OVERNIGHT_GUEST,
    'label': 'Allows overnight guests',
  },
  {
    'id': AmenitiesConstants.AMEN_PARKING_FREE,
    'label': 'Free parking on premises',
  },
  {
    'id': AmenitiesConstants.AMEN_PARKING_OFF_PAID,
    'label': 'Paid parking off premises',
  },
  {
    'id': AmenitiesConstants.AMEN_PARKING_STREET,
    'label': 'Free street parking',
  },
  {
    'id': AmenitiesConstants.AMEN_PATIO,
    'label': 'Patio or balcony',
  },
  {
    'id': AmenitiesConstants.AMEN_POOL,
    'label': 'Pool',
  },
  {
    'id': AmenitiesConstants.AMEN_WATERFRONT,
    'label': 'Waterfront',
  },
  {
    'id': AmenitiesConstants.AMEN_WORKSPACE,
    'label': 'Dedicated workspace',
  },
];

