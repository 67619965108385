import React from 'react';

export const embedScripts = () => {

  let stackAdaptScript = document.createElement('script');
  stackAdaptScript.innerHTML = `!function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function()
    {n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=
    !0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];
    z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');
    saq('ts', 'vF4tkhkM8nomKOOx44hPzg');`;

  let redditScript = document.createElement('script');
  redditScript.innerHTML = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?
      p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var
      t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var
      s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}
      (window,document);rdt('init','t2_sra675h0',

      {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"","email":"",
      "idfa":""});rdt('track', 'PageVisit');`;

  let metaScript = document.createElement('script');
  metaScript.innerHTML =
    `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '770370237636145');
      fbq('track', 'PageView');`;

  let rletsScript = document.createElement('script');
  rletsScript.type = 'text/javascript';
  rletsScript.src = '//cdn.rlets.com/capture_configs/230/8bd/2a3/0d0402f9af7d8a086eefae3.js';
  rletsScript.async = 'async';

  let vimarcEngagementTrackingPixel = document.createElement('img');
  vimarcEngagementTrackingPixel.innerHTML =
    `<img style="display:none;width:1px;"
      src="https://secure.adnxs.com/px?id=1626277&t=2" width="1" height="1" alt="" />`;

  let vimarcRetargetingTrackingPixel = document.createElement('img');
  vimarcRetargetingTrackingPixel.innerHTML =
    `<img src="https://ib.adnxs.com/seg?add=31478161" style="display:none" width="1" height="1" alt="" />`;

  let adsrvrTrackingPixel = document.createElement('img');
  adsrvrTrackingPixel.innerHTML =
    `<img height="1" width="1" style="border-style:none;" alt=""
      src="https://insight.adsrvr.org/track/pxl/?adv=twq7v3o&ct=0:m4l3l9u&fmt=3"/>`;

  document.body.append(
    stackAdaptScript,
    redditScript,
    metaScript,
    rletsScript,
    vimarcEngagementTrackingPixel,
    vimarcRetargetingTrackingPixel,
    adsrvrTrackingPixel,
  );
};

export const ConversionTrackingPixel = () => {
  // ----- Added on Houses component per Vimarc -----

  return (
    <>
      {/* Vimarc Conversion tracking pixel */}
      <img
        style={{display:'none', width:'1px'}}
        src="https://secure.adnxs.com/px?id=1626276&t=2" width="1" height="1" alt='' />
    </>
  );
};
