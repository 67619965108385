import React, { useCallback, useRef } from 'react';
import { Text } from '../../theme';
import { LinkButtonWithIcon } from '../../components';
import colors from '../../scss/color.module.scss';
import {
  GoogleMap,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useMobile } from '../../utils/hooks';
import { Marker } from '@react-google-maps/api';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import './ContactInfoCard.scss';
import withHttp from '../../utils/withHttp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const desktopContainerStyle = {
  width: '100%',
  height: '400px',
};

const mobileContainerStyle = {
  width: '100%',
  height: '50vh',
};

export default function ContactInfoCard ({ houseInfo, id }) {

  const { isMobile } = useMobile();

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const mapRef = useRef();
  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return 'Error loading...';
  if (!isLoaded) return 'Loading Maps...';

  const center = {
    lat: parseFloat(houseInfo.address.latitude),
    lng: parseFloat(houseInfo.address.longitude),
  };

  const markerIcon = {
    path: faMapMarkerAlt.icon[4],
    fillColor: '#004266',
    fillOpacity: 0.9,
    anchor: new google.maps.Point(
      faMapMarkerAlt.icon[0] / 2, // width
      faMapMarkerAlt.icon[1],   // height
    ),
    strokeWeight: 1,
    strokeColor: '#ffffff',
    scale: 0.075,
  };

  let hasNoInfo = !houseInfo.phone && !houseInfo.website && !houseInfo.email;

  function getMapLink () {
    // eslint-disable-next-line max-len
    return `https://www.google.com/maps/search/?api=1&query=${houseInfo.address.latitude},${houseInfo.address.longitude}`;
  }

  return (
    <div className={isMobile ? '' : 'rounded desktop'} >
      <div className='col px-md-4 pt-3' id='mobileContactCard'>
        <Text XX_large bold className="mb-2">
          Contact Info
        </Text>
        {houseInfo.allowWaitList &&
        <div className='bg-pagebg border border-primary rounded-1'>
          <div className='row p-3'>
            <div className='col-1'>
              <FontAwesomeIcon
                icon={['fas', 'clipboard-list']}
                color={colors.primary}
                size='lg'
              />
            </div>
            <div className='col-11'>
              <Text large bold >
                This house uses a waitlist system.
              </Text>
              <Text>
              If there is no availability and you are still interested in this house, please use the contact information
               below to get on the waitlist.
              </Text>
            </div>
          </div>
        </div>
        }
      </div>
      <div className={isMobile ? 'reverse' : ''}>
        <div className="col-12 pt-2 px-md-4">
          <Text large >
            {houseInfo.address.privateAddress ?
              'Address hidden for privacy'
              :
              houseInfo.address.latitude && houseInfo.address.longitude &&
                <a href={getMapLink()}
                  className="regular-link"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div>
                    {houseInfo.address.address1}
                  </div>
                  <div>
                    {houseInfo.address.address2}
                  </div>
                  <div>
                    {`${houseInfo.address.city} ${houseInfo.address.state}`}
                  </div>
                  <div>
                  </div>
                  <div>
                    {houseInfo.address.zipCode}
                  </div>
                </a>
            }
            {hasNoInfo ?
              <Text large>
                No contact info available
              </Text>
              :
              <Text large>
                {houseInfo.phone &&
                  <div className="mt-3">
                    <a href={`tel:${houseInfo.phone}`} className="regular-link">
                      {houseInfo.phone}
                    </a>
                  </div>
                }
                {houseInfo.website &&
                  <div className="mt-3">
                    <a href={withHttp(houseInfo.website)}
                      className="regular-link"
                      target="_blank"
                      rel="noopener noreferrer">
                      {houseInfo.website}
                    </a>
                  </div>
                }
                {houseInfo.email &&
                  <div className="mt-3">
                    <a href={`mailto:${houseInfo.email}`} className="regular-link">
                      {houseInfo.email}
                    </a>
                  </div>
                }
              </Text>}
          </Text>
          <div className="text-center mt-3 pb-3">
            <LinkButtonWithIcon
              type='primary'
              link={`/houseDetail?id=${id}#applicationForm`}
              label='View Application Process'
              height='40px'
              lineHeight='27px'
              iconPosition='left'
              icon={['fal', 'angle-double-down']}
              iconColor={colors.textwhite}
              iconSize='lg' />
          </div>
        </div>
        {houseInfo.address.latitude &&
          houseInfo.address.longitude &&
          !houseInfo.address.privateAddress &&
          <div className="col-12 mb-5 mb-md-0">
            <GoogleMap
              mapContainerStyle={isMobile ? mobileContainerStyle : desktopContainerStyle}
              center={center}
              zoom={16}
              onLoad={onLoad}
            >
              <Marker
                icon={markerIcon}
                position={center}
              />
            </GoogleMap>
          </div>}
      </div>
    </div>
  );
}
