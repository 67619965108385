import React from 'react';

export default function Paragraphs (props) {

  const {
    text,
  } = props;

  return (
    <p className="text-center">
      {text}
    </p>
  );
}
