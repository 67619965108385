import React, { useState, useEffect } from 'react';
import { Text } from '../../../theme';
import { Dropdown } from '../..';
import { useMobile } from '../../../utils/hooks';
import colors from '../../../scss/color.module.scss';
import { useGSModal } from '../../../utils/hooks/useGSModal';
import { setGuidedSearchValue } from '../../../features/guidedSearchSlice';
import { useSelector, useDispatch } from 'react-redux';
import { last } from 'lodash';

export function GSDropdownQuestion ({ question, index, viewable, options }) {
  const [visibility, setVisibility] = useState(true);
  const { isMobile } = useMobile();
  const { questionLabel } = useGSModal();
  const dispatch = useDispatch();
  const fieldValue = useSelector((state) => state.guidedSearch.searchFields[question.name]);
  const applyingForSelf = useSelector((state) => state.guidedSearch.utilityStates.applyingForSelf);
  const id = question.name + index;

  useEffect(() => {
    setVisibility(viewable);
    if (!viewable) {
      dispatch(setGuidedSearchValue({ field: question.name, value: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewable]);

  const handleChange = (e, question) => {
    if (question.name === 'convictions') {
      if (last(e)?.id === 'NON_APPLICABLE') {
        e = [last(e)];
        dispatch(setGuidedSearchValue({
          field: question.name, value: e,
        }));
      } else if (e[0]?.id === 'NON_APPLICABLE') {
        e.splice(0, 1);
        dispatch(setGuidedSearchValue({ field: question.name, value: e }));
      } else {
        dispatch(setGuidedSearchValue({ field: question.name, value: e }));
      }
    } else {
      dispatch(setGuidedSearchValue({ field: question.name, value: e }));
    }
  };

  return (
    <>
      {visibility && <div key={id} >
        <div className="row mb-5">
          <div className={isMobile ? 'col-12 pb-3' : 'col-6'}>
            <Text white large>{questionLabel(question, applyingForSelf)}</Text>
          </div>
          <div className={isMobile ? 'col-12 pb-3' : 'col-3'}>
            <div className="row">
              <Dropdown
                instanceId={id}
                isClearable={true}
                isMulti={question.isMulti || false}
                options={options}
                placeholder={question.placeholder}
                placeHolderStyle={{ color: colors.textwhite, fontSize: '16px', justifyStart: true }}
                isGuidedSearch={true}
                border={true}
                value={fieldValue || ''}
                updateSelection={(e) => handleChange(e, question)}
                blurInputOnSelect={false}
              />
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
