import React from 'react';

export function RadioBox ({ label, className, isDefaultChecked, onChange, value, name }) {

  return (
    <label className={`checkbox-label ${className}`} >
      <input
        className='form-check-input checkbox-input me-2'
        type='radio'
        name={name}
        checked={isDefaultChecked}
        onChange={onChange}
        value={value}
      />
      {label}
    </label>
  );
}
