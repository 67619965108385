import React from 'react';
import { Text } from '../../theme';
import CheckedList from './CheckedList';
import { useMobile } from '../../utils/hooks';
import {
  TransportationConstants, 
  TransportationData,
} from '../../AppData/GetHelpConstants/Transportation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from './../../scss/color.module.scss';

export default function Transportation ({ houseInfo }) {

  const { isMobile } = useMobile();
  let personalVehicleValue = '';

  for (let item in houseInfo.transportation) {
    const value = houseInfo.transportation[item];
    if (value === TransportationConstants.VEHICLE_ALLOWED ||
      value === TransportationConstants.VEHICLE_STAY ||
      value === TransportationConstants.VEHICLE_ALLOWED_RESTRICT) {
      personalVehicleValue = value;
    }
  }

  const findPersonalVehicleStatus = (element) => element.id == personalVehicleValue;
  let personalVehicleStatus = TransportationData.PERSONAL_VEHICLE.find(findPersonalVehicleStatus);

  return (
    <>
      <div className="row mt-3">
        <Text XX_large bold className="mb-4">
          Transportation
        </Text>
        <div className="col-8">
          <div className="row">
            <CheckedList
              returnedValues={houseInfo.transportation}
              comparisonList={TransportationData.TRANSPORTATION_OPTIONS}
              type={isMobile ? 'single' : ''}
              transportation={true}
            />
          </div>
          {personalVehicleStatus &&
          <Text large>
            <FontAwesomeIcon
              icon={['fas', personalVehicleStatus.icon]}
              color={colors.headerfooterbg}
              size='lg'
            />
            {personalVehicleStatus.label}
          </Text>
          }
        </div>
      </div>
    </>
  );
}
