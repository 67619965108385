import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { RHdropdownStyles } from './Dropdown';
import google_logo from '../../AppData/img/google_logo.png';
import colors from '../../scss/color.module.scss';
const { placeholderColor } = colors;

export default function AutoComplete ({
  placeholderStyle,
  border = false,
  componentState,
  setComponentState,
  setInvalidSearch,
}) {
  const autoCompleteServiceRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const [token, setToken] = useState(null);
  const [locationData, setLocationData] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [placeholder, setPlaceholder] = useState('Enter City or Zip Code');
  const KYBorderStates = ['KY', 'WV', 'VA', 'TN', 'IN', 'MO', 'OH', 'IL'];
  const KYBounds = {
    north: 39.147,
    south: 36.497,
    west: -89.571,
    east: -81.964,
  };

  const getGeocodeLocation = async (locationId) => {
    if (locationId) {
      let geocoder = new google.maps.Geocoder();
      await geocoder.geocode({ placeId: locationId }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          setLocationData(results[0]);
        } else {
          setInvalidSearch(true);
        }
      });
    } else {
      setInvalidSearch(true);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption?.value) {
      getGeocodeLocation(selectedOption.value);
    }
  };

  const formatPredictions = (suggestions) => {
    const filteredPredictions = [];
    suggestions.predictions.forEach((suggestion) => {
      if (suggestion?.terms && KYBorderStates.includes(suggestion.terms[1].value))
        filteredPredictions.push({
          value: suggestion.place_id,
          label: suggestion.description,
        });
    });

    setPredictions(filteredPredictions);

    return filteredPredictions;
  };

  const getPredictions = async (userInput) => {
    if (!userInput) {
      return;
    } else {
      const result = await autoCompleteServiceRef.current.getPlacePredictions({
        input: userInput,
        componentRestrictions: { country: 'us' },
        types: ['(regions)'],
        bounds: KYBounds,
        sessionToken: token,
      });

      return formatPredictions(result);
    }
  };

  const handleBlur = () => {
    if (predictions.length > 0 && autoCompleteRef.current.state.inputValue) {
      setSelectedOption(predictions[0]);
      getGeocodeLocation(predictions[0].value);
    }
  };

  const handleBackspace = (event) => {
    let backspaceEvent = event.type === 'keydown' && event.key === 'Backspace';
    if (backspaceEvent && componentState?.cityStateZip) {
      setSelectedOption('');
      setComponentState({
        ...componentState,
        cityStateZip: '',
        latitude: '',
        longitude: '',
      });
    }
  };

  const initAutocomplete = () => {
    setToken(new google.maps.places.AutocompleteSessionToken());
    autoCompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
  };

  useEffect(() => {
    initAutocomplete();

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('keydown', handleBackspace);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationData) {
      setComponentState({
        ...componentState,
        cityStateZip:
          locationData?.formatted_address || locationData?.address_components[0]?.long_name,
        latitude: locationData.geometry?.location.lat(),
        longitude: locationData.geometry?.location.lng(),
      });
      setInvalidSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  useEffect(() => {
    if (componentState.cityStateZip === '') {
      setSelectedOption('');
      setPlaceholder('Enter City or Zip Code');
    } else {
      setPlaceholder(componentState.cityStateZip);
    }
  }, [componentState.cityStateZip]);

  const Menu = ({ children, ...props }) => {
    return (
      <>
        <components.Menu {...props}>
          <div>
            {children}
            <img className="py-2" src={google_logo} alt="google logo" />
          </div>
        </components.Menu>
      </>
    );
  };

  let defaultPlaceHolderStyle = {
    color: placeholderColor,
    fontSize: '14px',
    justifyStart: false,
    ...placeholderStyle,
  };

  return (
    <AsyncSelect
      cacheOptions
      ref={autoCompleteRef}
      unstyled={true}
      instanceId="locationAutocomplete"
      className="location"
      isClearable={true}
      placeholder={placeholder}
      components={{ Menu }}
      styles={RHdropdownStyles}
      placeholderStyle={defaultPlaceHolderStyle}
      noOptionsMessage={() => 'No Results Found'}
      onBlur={handleBlur}
      onKeyDown={handleBackspace}
      onChange={handleSelectChange}
      value={selectedOption}
      loadOptions={getPredictions}
      withoutCarat={true}
      border={border}
      menuWidth="100%"
      centerPlaceholderText={true}
      centerCursor={true}
      multiline={true}
    />
  );
}
