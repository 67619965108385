import React from 'react';
export default function ItemList (props) {

  const { item, list, bullet, singleCol } = props;

  let itemInfo;

  const findItem = (element) => element.id == item;
  itemInfo = list.find(findItem);

  return (
    <>
      {itemInfo &&
        <div className={singleCol ? '' : 'col-6'}>
          <div className="pb-3">
            {bullet && <span>&#8226;&nbsp;</span>}
            {itemInfo.label}
          </div>
        </div>}
    </>
  );
}
