import React from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMobile } from '../../utils/hooks';
import './Search.scss';

export function Search ({ placeholder, handleSubmitSearchText, setSearchText}) {
  const { register, reset, handleSubmit } = useForm();
  const { isMobile } = useMobile();

  const resetSearch = () => {
    setSearchText(null);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitSearchText)}>
      <div className='input-group search-bar'>
        <span className='input-group-text bg-white border-end-0 pe-0 search-icon-button'>
          <FontAwesomeIcon className='search-icon'
            icon='search' />
        </span>
        <input
          type='text'
          className='form-control border-start-0 border-end-0 px-0'
          placeholder={placeholder}
          aria-label={placeholder}
          aria-describedby="basic-addon2"
          {...register('searchText')}>
        </input>
        <button className="exit-button border-start-0" type='button' onClick={resetSearch}>
          <FontAwesomeIcon className='search-icon'
            icon='times' />
        </button>
        {!isMobile && <input
          type='submit'
          value='Search'
          className='input-group-text search-button'
          id='basic-addon2'>
        </input>}
      </div>
    </form>
  );
}
