import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../theme';
import './MoreInfoModal.scss';

export default function MoreInfoModal ({ data: { id, label, title, description } }) {

  const tempText = description.split('\n').map((text, index) => {
    return (<p key={index}> {text} </p>);
  });

  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={label} aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header border-0">
            <div className=" col-11 d-flex justify-content-center">
              <Text bold extra_large >
                <FontAwesomeIcon icon={['fal', 'info-circle']} />
                {title}
              </Text>
            </div>
            <div className="col-1 d-flex justify-content-end">
              <button className="btn btn-link closeButtonLink" data-bs-dismiss="modal" aria-label="Close">
                <Text XXX_large>
                  <FontAwesomeIcon
                    className="m-0"
                    icon={['fal', 'times']}
                  />
                </Text>
              </button>
            </div>
          </div>
          <div className="modal-body text-wrapper pt-0">
            {tempText}
          </div>
        </div>
      </div>
    </div>
  );
}
