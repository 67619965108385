import React, { useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import classnames from 'classnames';
import { Dropdown } from '../components';
import {
  Button,
  RHLink,
  Text,
} from '../theme';
import Strings from '../AppData/Strings/ContactUsStrings.json';
import { ErrorText } from '../theme/wrappers/ErrorText';
import { ErrorMessage } from '@hookform/error-message';
import getImageByKey from '../utils/getImages';
import GoToTop from '../utils/goToTop';
import { RHImage } from '../theme/wrappers/RHImage';
import { tabTitle } from '../utils/tabTitle';

const createContactUsForm = async (request) => {
  const formData = new FormData();

  formData.append('first_name', request.first_name);
  formData.append('last_name', request.last_name);
  formData.append('job_title', request.job_title);
  formData.append('organization', request.organization);
  formData.append('method_of_contact', request.method_of_contact);
  formData.append('email', request.email);
  formData.append('phone', request.phone);
  formData.append('message', request.message);

  const { data: response } =
    await axios.post(`${process.env.REACT_APP_API_ROOT}/api/contact_us/contact_us/`, formData);

  return response;
};

const methodOfContactOptions = [
  { value: 'PHONE', label: 'Phone Call' },
  { value: 'EMAIL', label: 'Email' },
];

export default function ContactUs () {
  const [submissionError, setSubmissionError] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const selectInputRef = useRef();
  const { register, handleSubmit, reset, formState: { errors }, control, setValue } = useForm();
  const LIST_HOUSE_URL = process.env.REACT_APP_GETHELP_LIST_HOUSE_URL;
  tabTitle('Contact Us - Find Recovery Housing Now');

  const onClear = () => {
    selectInputRef.current.select.clearValue();
  };

  const { mutate } = useMutation(createContactUsForm, {
    onSuccess: () => {
      setSubmissionSuccess(true);
      setSubmissionError('');
      onClear();
      reset({});
      setValue('info.method_of_contact', methodOfContactOptions[0].value);

    },
    onError: (error) => {
      if (error.response.status == '400') {
        const errMsg = error.response.data;
        if (errMsg.email) {
          setSubmissionError('Submission error: Invalid email address');
        } else {
          setSubmissionError('Submission error');
        }
      } else {
        setSubmissionError('Submission error');
      }
    },
  });

  const handleChange = (e) => {
    if (e) {
      setValue('info.method_of_contact', e.value);
    }
  };

  const getField = (obj) => {
    const name = `info.${obj.name}`;

    switch (obj.type) {
      case 'text-area':
        return (
          <textarea
            {...register(name, { required: { value: obj.required, message: `${obj.label} is required` } })}
            className='form-control'
            id={name}
            rows='6'>
          </textarea>);
      case 'email':
        // eslint-disable-next-line no-case-declarations
        const emailRegex =
          // eslint-disable-next-line
          /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

        return (
          <input
            {...register(name, {
              required: { value: obj.required, message: `${obj.label} is required` },
              pattern: {
                value: emailRegex,
                message: 'invalid email address',
              },
            })}
            id={obj.name}
            type='text'
            className='form-control'
            aria-describedby={obj.label}
          />);
      case 'method':
        return (
          <>
            <Controller
              control={control}
              name={name}
              isClearable
              defaultValue={methodOfContactOptions[0].value}
              rules={{ required: { value: true, message: 'Contact method is required' } }}
              render={({ field: { onChange } }) => (
                <>
                  <Dropdown
                    reference={selectInputRef}
                    border={true}
                    instanceId='checkoutAudienceType'
                    options={methodOfContactOptions}
                    placeholder={methodOfContactOptions[0].label}
                    updateSelection={onChange, handleChange}
                    desktopStyle={true}
                    dropdownColor='white'
                  />
                </>)}
            />
          </>
        );
      default:
        return (
          <input
            {...register(name, { required: { value: obj.required, message: `${obj.label} is required` } })}
            id={obj.name}
            type={obj.type}
            className='form-control'
            aria-describedby={obj.label}
          />);
    }
  };

  const onSubmitOrder = (data) => {
    mutate(data.info);
  };

  return (
    <>
      <div className='row p-0 m-0'>
        <div className="col-4 p-0 card border-0 d-none d-md-block">
          <RHImage
            image={getImageByKey('heroColor')}
            className="w-100 h-100"
            alt='Find Recovery Housing' />
          <span className="card-img-overlay d-flex align-items-center justify-content-center">
            <img
              src={getImageByKey('logoWhite')}
              className="img-fluid"
              alt='Find Recovery Housing' />
          </span>
        </div>
        <div className='col-12 col-md-8 p-0'>
          <div className='text-center'>
            <Text bold gray className='mt-5'>
              {Strings.CONTACT_US.SUPER_HEADER}
            </Text>
            <Text extra_large className='mt-2'>
              {Strings.CONTACT_US.HEADER}
            </Text>
          </div>
          <form onSubmit={handleSubmit(onSubmitOrder)}>
            <div className='mx-4 mt-4 pt-0'>
              <Text gray className='text-center mb-4'>
                {Strings.CONTACT_US.FILL_OUT}
                <a href={LIST_HOUSE_URL} target='_blank' rel='noreferrer'>click here</a>{`. `}
                {Strings.CONTACT_US.OWNER_OPERATOR}
                <RHLink to={Strings.CONTACT_US.TECH_ASSIST_LINK}>click here</RHLink>{`.`}
              </Text>
              <div className='col-12 d-flex justify-content-center'>
                <div>
                  {Strings.CONTACT_US.FIELDS.map(
                    (obj, index) =>
                      <div className='col-12' key={`rec-resources-${index}`}>
                        <div className='mb-3 row d-flex justify-content-start' >
                          <div>
                            <label
                              htmlFor={`info.${obj.name}`}
                              className={classnames(
                                'col-form-label',
                                'checkout-label',
                                { 'required': obj.required })}>
                              {obj.label}
                            </label>
                          </div>
                          <div className='col-md-12'>
                            {getField(obj)}
                            <ErrorMessage
                              errors={errors}
                              name={`info.${obj.name}`}
                              render={({ message }) =>
                                <ErrorText className='px-1'>
                                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                                  {message}
                                </ErrorText>}>
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>,
                  )}
                </div>
              </div>
              <div className='border-0 mt-4 mb-5 mx-4 text-center'>
                {submissionError && <Text className='w-100 pt-1 text-center'>
                  <p className='digital-product-error'>{submissionError}</p>
                </Text>}
                {submissionSuccess && <Text className='w-100 pt-1 text-center'>
                  <p className='digital-product-error'>{Strings.CONTACT_US.SUCCESS_MESSAGE}</p>
                </Text>}
                <Button
                  submit
                  primary
                  width='229px'
                  height='36px'
                  fontSize='16px'>
                  {Strings.CONTACT_US.SUBMIT}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <GoToTop />
    </>
  );
}
