import styled from 'styled-components';

// Creates the opacity and color overlay for the full height mobile offcanvas nav bar

const NavOverlay = styled.div`
    opacity:    0.9; 
    background-color: #004266; 
    height:     100%; 
    `;

export { NavOverlay };
