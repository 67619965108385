export const StructureTypesConstants = {
  SINGLE_FAMILY_HOUSE: 'SINGLE_FAMILY_HOUSE',
  APARTMENT_UNITS: 'APARTMENT_UNITS',
  DUPLEX_OR_TRIPLEX: 'DUPLEX_OR_TRIPLEX',
  APARTMENT_BUILDING: 'APARTMENT_BUILDING',
  CONDOMINIUM_UNITS: 'CONDOMINIUM_UNITS',
  OTHER: 'OTHER',
};

export const StructureTypesData = [
  {
    'id': StructureTypesConstants.SINGLE_FAMILY_HOUSE,
    'label': 'Single Family House',
  },
  {
    'id': StructureTypesConstants.APARTMENT_UNITS,
    'label': 'Apartment Units',
  },
  {
    'id': StructureTypesConstants.DUPLEX_OR_TRIPLEX,
    'label': 'Duplex or Triplex',
  },
  {
    'id': StructureTypesConstants.APARTMENT_BUILDING,
    'label': 'Apartment Building',
  },
  {
    'id': StructureTypesConstants.CONDOMINIUM_UNITS,
    'label': 'Condominium Units',
  },
  {
    'id': StructureTypesConstants.OTHER,
    'label': 'Other',
  },
];
