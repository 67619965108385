import React from 'react';
import Strings from '../../../AppData/Strings/GuidedSearch.json';
import { Text } from '../../../theme';
import Paragraphs from '../../shared/Paragraphs';
import { LinkButton } from '../../../theme/wrappers/LinkButton';
import { LinkButtonWithIcon } from '../../shared/LinkButtonWithIcon';
import getImageByKey from '../../../utils/getImages';
import { useMobile } from '../../../utils/hooks';
import { useGSModal } from '../../../utils/hooks/useGSModal';
import { useSearch } from '../../../utils/useSearch';
import { initialSearchState } from '../../../features/searchSlice';
import { setSearchState } from '../../../features/searchSlice';
import store from '../../../store';
import ReactGA from 'react-ga4';
import { createGSAnalytics } from '../../../utils/analytics';

export function GSResults () {

  const { isMobile } = useMobile();
  const { onGSModalClose, changePageDown, buildSearchData } = useGSModal();
  const { onSubmit } = useSearch();

  return (
    <div className="row d-flex align-content-center">
      <div className="col text-center text-white">
        <Text XXX_large white className="pb-4">
          {Strings.PAGES[5].header}
        </Text>
        <Text white large className="pb-4">
          {Strings.PAGES[5].PARAGRAPHS.map((obj, index) =>
            <Paragraphs key={index} text={obj.text} />)}
        </Text>
        <LinkButton
          to=''
          onClick={(e) => {
            e.preventDefault();
            onGSModalClose();
            const updatedSearchData = buildSearchData(initialSearchState);
            setSearchState(updatedSearchData);
            ReactGA.event({
              category: 'viewMatchingRecoveryHouses',
              action: 'viewMatchingRecoveryHouses',
              label: 'gsResultsViewMatchingRecoveryHouses',
            });
            createGSAnalytics(store.getState().guidedSearch.searchFields);
            onSubmit(updatedSearchData);
          }}
          width='285px'
          height='45px'
          lineheight='32px'
        >View matching Recovery Houses</LinkButton>
      </div>
      <img
        src={getImageByKey(isMobile ? 'MoreFilters_Mobile' : 'MoreFilters')}
        className="pt-4 img-fluid"
        alt={'More Filters'} />
      <LinkButtonWithIcon
        link=''
        onClick={() => changePageDown(store.getState().guidedSearch.utilityStates.page)}
        label="Back"
        icon={['far', 'angle-double-left']}
        width="150px"
        height='45px'
        lineHeight='35px'
        iconPosition="left"
      />
    </div>
  );
}
