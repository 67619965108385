import { LanguageConstants } from '../../AppData/GetHelpConstants/Languages';
import { ResidentsServedConstants, ResidentsServedData } from '../../AppData/GetHelpConstants/ResidentsServed';
import { resetState, setGuidedSearchUtilityValue } from '../../features/guidedSearchSlice';
import { useDispatch } from 'react-redux';
import store from '../../store';
import { cloneDeep } from 'lodash';

export const useGSModal = () => {
  const dispatch = useDispatch();

  const buildSearchData = (initialSearchState) => {
    let tempSearchData = cloneDeep(initialSearchState);
    const guidedSearchState = store.getState().guidedSearch.searchFields;
    for (const [key, value] of Object.entries(guidedSearchState)) {
      if (value) {
        switch (key) {
          case 'describeYou':
            tempSearchData.role = value.id;
            break;
          case 'currentRes':
            if (!guidedSearchState.cityStateToBeHoused) {
              tempSearchData.cityStateZip = guidedSearchState.currentRes.id;
            }
            break;
          case 'cityStateToBeHoused':
            tempSearchData.cityStateZip = guidedSearchState.cityStateToBeHoused.id;
            break;
          case 'gender':
            tempSearchData.gender = value.id;
            break;
          case 'LGBTFriendly':
            if (value.id.includes('yes')) {
              tempSearchData.characteristics.push(ResidentsServedData.find(
                (resident) => resident.id === ResidentsServedConstants.POP_LGBTQ));
            }
            break;
          case 'speakEnglish':
            if (value.id.includes('yes')) {
              tempSearchData.language = LanguageConstants.LANG_ENGLISH;
            }
            break;
          case 'primaryLanguage':
            tempSearchData.language = value.id;
            break;
          case 'pregnant':
            if (value.id.includes('yes')) {
              tempSearchData.characteristics.push(ResidentsServedData.find(
                (element) => element.id === ResidentsServedConstants.POP_PREGNANCY));
            }
            break;
          case 'under18':
            if (value.id.includes('yes')) {
              tempSearchData.characteristics.push(ResidentsServedData.find(
                (element) => element.id === ResidentsServedConstants.POP_UNDER_18));
            }
            break;
          case 'undergoingMTA':
            if (value.id.includes('yes')) {
              tempSearchData.moudCapable = true;
            }
            break;
          case 'convictions':
            tempSearchData.legalExceptions = value;
            break;
          case 'useNicotineProducts':
            if (value.id.includes('yes')) {
              tempSearchData.smokingAllowed = true;
            }
            break;
        }
      }
    }

    return tempSearchData;
  };

  function gotoPage (pageNumber) {
    dispatch(setGuidedSearchUtilityValue({ field: 'page', value: pageNumber }));
  }
  function changePageUp (page) {
    dispatch(setGuidedSearchUtilityValue({ field: 'page', value: page + 1 }));
  }
  function changePageDown (page) {
    dispatch(setGuidedSearchUtilityValue({ field: 'page', value: page - 1 }));
  }

  const onGSModalOpen = () => {
    dispatch(resetState());
    dispatch(setGuidedSearchUtilityValue({ field: 'modalVisible', value: true }));
  };

  const onGSModalClose = () => {
    dispatch(setGuidedSearchUtilityValue({ field: 'modalVisible', value: false }));
  };

  const questionLabel = (question, applyingForSelf) => {
    if (applyingForSelf) {
      return question.label.myself;
    } else {
      return question.label.other != null ? question.label.other : question.label.myself;
    }
  };

  return {
    changePageUp,
    changePageDown,
    gotoPage,
    questionLabel,
    onGSModalOpen,
    onGSModalClose,
    buildSearchData,
  };
};

export default useGSModal;
