import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import './ResourceContentType.scss';

export function ResourceContentType ({ content_type }) {

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    /* eslint-disable no-unused-vars */
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    /* eslint-enable no-unused-vars */
  });

  const content_type_icon = (content_type) => {
    switch (content_type) {
      case 'Video':
        return {
          icon: 'play',
          color: '#3F51B5',
          tooltip: 'This is a link to an external video',
        };
      case 'Audio':
        return {
          icon: 'headphones',
          color: '#4CAF50',
          tooltip: 'This is a link to an audio resource',
        };
      case 'Image':
        return {
          icon: 'image',
          color: '#03A9F4',
          tooltip: 'This is an infographic or other image file',
        };
      case 'Document':
        return {
          icon: 'file-alt',
          color: '#FF9800',
          tooltip: 'This is a downloadable document',
        };
      case 'Web':
        return {
          icon: 'globe',
          color: '#0BA6A1',
          tooltip: 'This is a link to an external website',
        };
      default:
        return {
          icon: 'file-alt',
          color: '#FF9800',
          tooltip: '',
        };
    }
  };

  return (
    <>
      <Tooltip placement="top" trigger='hover' overlay={<span>{content_type_icon(content_type).tooltip}</span>}>
        <div
          className='content-type'
          style={{
            backgroundColor: content_type_icon(content_type).color,
          }}
        >
          <FontAwesomeIcon
            className='icon'
            icon={['fas', content_type_icon(content_type).icon]}
          />
        </div>
      </Tooltip>
    </>
  );
}
