import React from 'react';
import { Text } from '../../../theme';
import Strings from '../../../AppData/Strings/HousesStrings';
import './SearchFilters.scss';
import { ServicesData } from '../../../AppData/GetHelpConstants/Services';
import { Checkbox } from '../../../components';

export function ServicesSearchFilters ({ searchStateCopy, setSearchStateCopy }) {

  return (
    <div className='accordion-item search-border'>
      <div className='accordion-header' id="headingSeven">
        <button
          type='button'
          className="accordion-button collapsed p-0 search-format"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSeven"
          aria-expanded="false"
          aria-controls="collapseSeven"
        >
          <Text bold large className='text-left mb-2 col'>
            {Strings.Houses.SERVICES_PROGRAMS.text}
          </Text>
        </button>
      </div>
      <div
        id="collapseSeven"
        className="accordion-collapse collapse"
        aria-labelledby="headingSeven"
        data-bs-parent="#searchFiltersAccordion">
        <div className='row accordion-body'>
          <div className='row pe-2'>
            {ServicesData.map((service, index) =>
              <Checkbox
                label={service.label}
                key={`${service.id}-${index}`}
                className={'my-3 col-12 col-lg-6'}
                onChange={(e) => {
                  const tempSearchData = {...searchStateCopy.services};
                  tempSearchData[service.id] = e.target.checked;
                  setSearchStateCopy({ ...searchStateCopy, services: tempSearchData });
                }}
                isDefaultChecked={searchStateCopy.services[service.id] || false}
              />)}
          </div>
        </div>
      </div>
    </div >
  );
}
