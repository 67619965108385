import React from 'react';
import { CurrencyInput, Dropdown } from '../../../components';
import { Text } from '../../../theme';
import Strings from '../../../AppData/Strings/HousesStrings';
import './SearchFilters.scss';
import { PaymentMethodsData } from '../../../AppData/GetHelpConstants/Payment';
import Slider from 'rc-slider';

export function FinancesSearchFilters ({ searchStateCopy, setSearchStateCopy }) {

  const handleSlider = (e) => {
    setSearchStateCopy({ ...searchStateCopy, avgCostMin: e[0], avgCostMax: e[1] });
  };

  return (
    <div className='accordion-item search-border'>
      <div className='accordion-header' id='headingThree'>
        <button
          type='button'
          className='accordion-button collapsed p-0 search-format'
          data-bs-toggle='collapse'
          data-bs-target='#collapseThree'
          aria-expanded='false'
          aria-controls='collapseThree'
        >
          <Text bold large className='text-left mb-2 col'>
            {Strings.Houses.FINANCES.text}
          </Text>
        </button>
      </div>
      <div
        id='collapseThree'
        className='accordion-collapse collapse'
        aria-labelledby='headingThree'
        data-bs-parent='#searchFiltersAccordion'
      >
        <div className='row accordion-body'>
          <div className='col-12 col-lg-6 pe-2'>
            <Slider
              className='mb-5'
              range
              min={0}
              max={1600}
              marks={{ 0: '$0', 1600: '$1600' }}
              value={[searchStateCopy.avgCostMin, searchStateCopy.avgCostMax]}
              tipFormatter={(value) => `$ ${value}`}
              tipsProps={{ placement: 'top', visible: true }}
              allowCross={false}
              onChange={handleSlider}
            />
            <div className='row row-cols-2 my-3'>
              <label className='label-text'>
                {Strings.Houses.MIN_RENT.text}
              </label>
              <CurrencyInput
                onChange={(e) => setSearchStateCopy({ ...searchStateCopy, avgCostMin: e.target.value })}
                value={searchStateCopy.avgCostMin || ''}
              />
            </div>
            <div className='row row-cols-2 my-3'>
              <label className='label-text'>
                {Strings.Houses.MAX_RENT.text}
              </label>
              <CurrencyInput
                onChange={(e) => setSearchStateCopy({ ...searchStateCopy, avgCostMax: e.target.value })}
                value={searchStateCopy.avgCostMax || ''}
              />
            </div>
            <div className='row row-cols-2 my-3'>
              <label className='label-text'>
                {Strings.Houses.PAYMENT_TYPES.text}
              </label>
              <Dropdown
                isClearable={true}
                isMulti={false}
                options={PaymentMethodsData}
                instanceId='paymentTypesSearchFilters'
                placeholder={Strings.Houses.SELECT.text}
                border={true}
                value={PaymentMethodsData.find((element) => element.id === searchStateCopy.paymentTypes) || ''}
                updateSelection={(value) =>
                  setSearchStateCopy({...searchStateCopy, paymentTypes: value ? value.id : ''})}
              />
            </div>
          </div>
          {/* -------Commented out for Alpha release------- */}
          {/* <div className='col-12 col-lg-6 ps-3'>
            <div className='my-3'>
              <input className='form-check-input me-2' type='checkbox' />
              <label>
                {Strings.Houses.EXCLUDE_HOUSES_THAT_REQUIRES_DEPOSIT.text}
              </label>
            </div>
            <div className='my-3'>
              <input className='form-check-input me-2' type='checkbox' />
              <label>
                {Strings.Houses.SHOW_HOUSES_ACCEPT_WEEKLY_PAYMENTS.text}
              </label>
            </div>
            <div className='my-3'>
              <input className='form-check-input me-2' type='checkbox' />
              <label>{Strings.Houses.SHOW_SUBSIDIZED_HOUSES.text}</label>
            </div>
            <div className='my-3'>
              <input className='form-check-input me-2' type='checkbox' />
              <label>{Strings.Houses.SHOW_FREE_HOUSES.text}</label>
            </div>
          </div> */}
        </div>
      </div>
      <hr></hr>
    </div>
  );
}
