export const TransportationConstants = {
  PUBLIC: 'PUBLIC',
  RIDESHARE: 'RIDESHARE',
  OWN_VEHICLE: 'OWN_VEHICLE',
  IN_HOUSE: 'IN_HOUSE',
  VEHICLE_ALLOWED: 'VEHICLE_ALLOWED',
  VEHICLE_STAY: 'VEHICLE_STAY',
  VEHICLE_ALLOWED_RESTRICT: 'VEHICLE_ALLOWED_RESTRICT',
};

export const TransportationData = {
  TRANSPORTATION_OPTIONS: [
    {
      id: TransportationConstants.PUBLIC,
      label: 'Near public transportation',
      icon: 'bus',
    },
    {
      id: TransportationConstants.RIDESHARE,
      label: 'Taxi or rideshare (Uber,Lyft,etc.)',
      icon: 'taxi',
    },
    {
      id: TransportationConstants.OWN_VEHICLE,
      label: 'Free parking for own vehicle',
      icon: 'car',
    },
    {
      id: TransportationConstants.IN_HOUSE,
      label: 'In house transportation service',
      icon: 'shuttle-van',
    },
  ],
  PERSONAL_VEHICLE: [
    {
      id: TransportationConstants.VEHICLE_ALLOWED,
      label: 'Personal vehicles allowed',
      icon: 'parking',
    },
    {
      id: TransportationConstants.VEHICLE_STAY,
      label: 'Personal vehicles not allowed during stay',
      icon: 'parking',
    },
    {
      id: TransportationConstants.VEHICLE_ALLOWED_RESTRICT,
      label: 'Personal vehicles allowed with some restrictions',
      icon: 'parking',
    },
  ],
};

