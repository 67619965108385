import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ArrowButton.scss';

export function ArrowButton ({ type, direction, onClick }) {
  const icon = direction == 'prev' ?
    type == 'circle' ? 'chevron-circle-left' : 'chevron-left' :
    type == 'circle' ? 'chevron-circle-right' : 'chevron-right';

  return (
    <>
      <button onClick={onClick} className='border-0 text-placeholder arrow-button'>
        {type == 'circle' ?
          <FontAwesomeIcon className='m-0 p-0' icon={['fas', `${icon}`]} size='2x' />
          :
          <FontAwesomeIcon className='m-0' icon={['far', `${icon}`]} size='lg' />
        }
      </button>
    </>
  );
}
