import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { Dropdown } from './Dropdown';

const getDropdownOptions = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, endpoint] = queryKey;
  const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/api/${endpoint}`);

  return data;
};

export function DynamicDropdown ({
  endpoint,
  updateSelection,
  instanceId,
  isClearable=false,
  isMulti=false,
  menuWidth='100%',
  placeholder='',
  desktopStyle = false,
  mobileStyle = false,
  border=false,
  placeHolderStyle = {},
  dropdownColor,
}) {
  const { isLoading, isSuccess, error, data } = useQuery([instanceId, endpoint], getDropdownOptions);

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <>
      { isSuccess ?
        <Dropdown
          options={data.results}
          isClearable={isClearable}
          isMulti={isMulti}
          instanceId={instanceId}
          isLoading={isLoading}
          menuWidth={menuWidth}
          placeholder={placeholder}
          updateSelection={updateSelection}
          desktopStyle={desktopStyle}
          mobileStyle={mobileStyle}
          border={border}
          placeHolderStyle={placeHolderStyle}
          dropdownColor={dropdownColor}
        /> :
        null
      }
    </>
  );
}
