import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../scss/color.module.scss';
const {
  favorites,
  textdisabled,
} = colors;
import { Text } from '../../theme';
import Gender from './Gender';
import { LinkButtonWithIcon } from '../../components';
import 'react-multi-carousel/lib/styles.css';
import HDCarousel from './HDCarousel';
import MoreInfoModal from '../../components/shared/MoreInfoModal';
import { FavoriteButton } from '../../components';
import MoreInfo from '../../AppData/Strings/MoreInfo.json';
import './MobileHeaderSection.scss';
import MoreInfoLink from './MoreInfoLink';
import { StatusIndicator } from '../../components';
import { RHImage } from '../../theme/wrappers/RHImage';
import getImageByKey from '../../utils/getImages';
import Certifications from '../../components/shared/Certification';
import { DistanceLabel } from '../../components/shared/distanceLabel';

export default function MobileHeaderSection ({ id, houseInfo }) {

  return (
    <div>
      {houseInfo.images.length > 0 ?
        <div className="mb-3 mobile-house-img-carousel px-0">
          <HDCarousel houseInfo={houseInfo} />
        </div>
        :
        <div className='mb-3 px-0'>
          <RHImage
            image={getImageByKey('NoPhoto')}
            className="img-fluid rounded-2"
            alt="No photo added"
          />
        </div>
      }
      <div className="row d-flex justify-content-between">
        <div className="col col-7">
          <Text XX_large bold>{houseInfo.name}</Text>
        </div>
        <div className="col-2 me-4">
          <button
            className="circleButton pt-0"
            onClick={(e) => { e.preventDefault(); window.print(); }}>
            <FontAwesomeIcon icon={['fal', 'print']} color={textdisabled} size='lg' />
          </button>
        </div>
        <div className="col-2">
          <div className="circleButton me-4">
            <FavoriteButton
              domain={'House'}
              backgroundColor={'mediumgray'}
              type="button"
              className='mx-5 p-5'
              favoriteKey={id}
              defaultValue={true}
              color={favorites}
              icon={['fal', 'star']}
              size={'lg'}
            />
          </div>
        </div>
      </div>
      <div className="col mt-2">
        <Text gray large>
          {houseInfo.address.city && houseInfo.address.state &&
            <DistanceLabel
              house={houseInfo}
            />
          }
        </Text>
      </div>
      <div className="col mt-4">
        <StatusIndicator
          status={houseInfo.status}
          updatedAt={houseInfo.updatedAt}
          type='leftJustify' />
      </div>
      <div className="col mt-4 d-flex justify-content-start">
        {houseInfo.genderType &&
          <div className="pe-4">
            <Text darkBlue bold large>
              <Gender
                genderServed={houseInfo.genderType}
                iconSize='lg'
              />
            </Text>
          </div>
        }
      </div>
      <div className="row d-flex align-items-center">
        <div className="col mt-4">
          {houseInfo.certifications.certs.length > 0 &&
            <>
              <Certifications houseInfo={houseInfo} />
              <MoreInfoLink
                icon='question-circle'
                target={MoreInfo.HOUSE_DETAILS.certification.id}
                text='What is this?'
              />
              <MoreInfoModal
                data={MoreInfo.HOUSE_DETAILS.certification}
              />
            </>
          }
        </div>
      </div>
      <div className="col mt-5 d-flex justify-content-center">
        <LinkButtonWithIcon
          link={`/houseDetail?id=${id}#contactInfoCardMobile`}
          label='View contact info'
          height='40px'
          lineHeight='27px'
          icon={['far', 'angle-double-down']}
          type='primary'
          iconPosition="left"
        />
      </div>

      <div className="col">
        <div className="row mt-5 pb-5 border-bottom border-placeholder">
          {houseInfo.description &&
            <Text large>
              <span className="fw-bold">
                House Description:
              </span>
              {' ' + houseInfo.description}
            </Text>
          }
          {houseInfo.abstinenceDefinition &&
            <Text large className="mt-3">
              <span className="fw-bold">
                Our Definition of Abstinence:
              </span>
              {' ' + houseInfo.abstinenceDefinition}
            </Text>
          }
          <div id="contactInfoCardMobile" />
        </div>
      </div>
    </div>
  );
}
