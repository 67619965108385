export const ResidentRequirementsConstants = {
  REQ_CHORES: 'REQ_CHORES',
  REQ_MEETINGS: 'REQ_MEETINGS',
  REQ_CURFEW: 'REQ_CURFEW',
};

export const ResidentRequirementsData = [
  {
    'id': ResidentRequirementsConstants.REQ_CHORES,
    'label': 'Requires Chores',
  },
  {
    'id': ResidentRequirementsConstants.REQ_MEETINGS,
    'label': 'Requires house meetings',
  },
  {
    'id': ResidentRequirementsConstants.REQ_CURFEW,
    'label': 'Has curfew',
  },
];
