import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../theme';
import colors from '../../scss/color.module.scss';
const { lightgreen, error, headerfooterbg } = colors;
import './BedTypes.scss';
import CheckedList from './CheckedList';
import { AccessibilityData } from '../../AppData/GetHelpConstants/Accessibility';
import { useMobile } from '../../utils/hooks';
import getImageByKey from '../../utils/getImages';
import { HouseStatusData } from '../../AppData/GetHelpConstants/HouseStatus';
import { StructureTypesData } from '../../AppData/GetHelpConstants/StructureTypes';

export default function BedTypes ({ houseInfo }) {

  const { isMobile } = useMobile();

  const BedPill = ({ label, number, type }) => {

    return (
      <div className="border border-placeholder rounded p-2 bg-white">
        <Text bold extra_large={!isMobile} large={isMobile} className="mb-2">
          {label}
        </Text>
        <Text large>
          {number || number == 0 ? number + ` ${type ? type : ''}` : 'unavailable'}
        </Text>
      </div>
    );
  };

  const BedCard = ({
    title,
    availability,
    image,
    imageAlt,
    rent,
    description,
    ADA,
    timeUpdated,
  }) => {
    return (
      <div className="border border-placeholder rounded mt-3 p-2 bg-white h-100">
        <Text className="mb-2" bold extra_large>
          {title}
          <div style={{display: 'inline'}} className="ps-3 text-end">
            {ADA && <FontAwesomeIcon className='align-items-end' icon={['far', 'wheelchair']}
              color={headerfooterbg} size='sm' />}
          </div>
        </Text>
        <div className="col mb-2">
          {availability ?
            <>
              <div className="pe-1">
                <FontAwesomeIcon icon={['fas', 'circle']} color={lightgreen} size='sm' />
                <span className="fw-bold">{(HouseStatusData[1].label)}</span>
              </div>
            </>
            :
            <>
              <div className="pe-1">
                <FontAwesomeIcon icon={['fas', 'circle']} color={error} size='sm' />
                <span className="fw-bold">{(HouseStatusData[2].label)}</span>
              </div>
            </>
          }
        </div>
        <Text className="mb-2" gray>
          {timeUpdated && `(as of ${timeUpdated})`}
        </Text>
        <div className="text-center mb-2">
          <img className="bedImage" src={image && image} alt={imageAlt && imageAlt} />
        </div>
        <Text large className="mb-2" bold>
          {rent && rent > 0 && `From $${rent}/month`}
        </Text>
        <Text large className="mb-2" >
          {description && description}
        </Text>
      </div>
    );
  };

  return (
    <>
      <div className="col mt-3 mx-2">
        <Text XX_large bold className="mb-4">
          Bed Types
        </Text>
        <div className="row">
          <div className='p-1 col-6 col-sm-6 col-md-4'>
            <BedPill
              label={'Total Beds'}
              number={houseInfo.bedTypes.totalBeds}
              type={'beds'}
            />
          </div>
          <div className='p-1 col-6 col-sm-6 col-md-4'>
            <BedPill
              label={'Total Bathrooms'}
              number={houseInfo.bedTypes.totalBaths}
              type={'bathrooms'}
            />
          </div>
          <div className='p-1 col-6 col-sm-6 col-md-4'>
            {houseInfo.bedTypes.recommendedStay &&
              <BedPill
                label={'Recommended stay'}
                number={houseInfo.bedTypes.recommendedStay}
              />
            }
          </div>
        </div>
        {houseInfo.bedTypes.accommodations && houseInfo.bedTypes.accommodations.length > 0 &&
          <div className="row">
            {houseInfo.bedTypes.accommodations.map((bedType) => (
              <div className='p-1 col-12 col-sm-12 col-md-4' key={bedType.id}>
                <BedCard
                  title={bedType.name}
                  availability={bedType.freeBeds > 0}
                  image={bedType.image <= 0 ? (getImageByKey('NoPhoto')) : bedType.image }
                  imageAlt={bedType.description}
                  rent={bedType.avgCost}
                  description={bedType.description}
                  ADA={bedType.adaRoomsAvailable}
                  timeUpdated={new Date(bedType.lastUpdated).toLocaleDateString('en-US')}
                />
              </div>
            ))}
          </div>
        }
        <Text XX_large bold className="mb-4 mt-4">
          Accessibility
        </Text>
        <div className="col">
          <CheckedList
            returnedValues={houseInfo.accessibility}
            comparisonList={AccessibilityData}
            type={'single'}
          />
        </div>
        {houseInfo.structureType &&
        <>
          <Text XX_large bold className="mb-4 mt-4">
            House Type
          </Text>
          <div className="col">
            {StructureTypesData.find((item) => item.id === houseInfo.structureType).label}
          </div>
        </>
        }
      </div>
    </>
  );
}
