import React, { useState, useEffect } from 'react';

// if setting up the CSS is tricky, you can add this to your page somewhere:
// <link rel="stylesheet" href="https://unpkg.com/react-spring-bottom-sheet/dist/style.css" crossorigin="anonymous">
import 'react-spring-bottom-sheet/dist/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text, Button } from '../../theme';
import { Dropdown } from '../../components';
import BottomSheetDrawer from '../../theme/wrappers/BottomSheetDrawer';
import { SearchDistanceData } from '../../AppData/GetHelpConstants/LocationDistance';
import { ErrorText } from '../../theme/wrappers/ErrorText';
import ReactGA from 'react-ga4';

// ----- Removed for Alpha -----
// import { SearchDistanceData } from '../../AppData/GetHelpConstants/LocationDistance';
import { createOptions } from '../../utils/createDropdownOptions';
import { useSearch } from '../../utils/useSearch';
import './MobileSearchBottomSheet.scss';
import { RolesData, rolesWithoutOther } from '../../AppData/GetHelpConstants/Roles';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchValue } from '../../features/searchSlice';
import store from '../../store';
import AutoComplete from '../../components/shared/AutoComplete';

export default function MobileSearchBottomSheet ({ open, close }) {

  const { onSubmit } = useSearch();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.search.role);
  const cityStateZip = useSelector((state) => state.search.cityStateZip);
  const latitude = useSelector((state) => state.search.latitude);
  const longitude = useSelector((state) => state.search.longitude);
  const radius = useSelector((state) => state.search.radius);
  const gender = useSelector((state) => state.search.gender);
  const initialState = { role, gender, cityStateZip, latitude, longitude, radius };
  const [searchStateCopyForMobile, setSearchStateCopyForMobile] = useState(initialState);
  const [invalidSearch, setInvalidSearch] = useState(false);

  useEffect(() => {
    setSearchStateCopyForMobile(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender, cityStateZip, role, latitude, longitude, radius]);

  const handleSearch = () => {
    for (const [key, value] of Object.entries(searchStateCopyForMobile)) {
      dispatch(setSearchValue({ field: key, value }));
    }

    if (
      searchStateCopyForMobile.radius &&
      searchStateCopyForMobile.cityStateZip &&
      !searchStateCopyForMobile.latitude
    ) {
      setInvalidSearch(true);
    } else {
      onSubmit(store.getState().search);
      setInvalidSearch(false);
    }

    ReactGA.event({
      category: 'search',
      action: 'searchButtonClicked',
      label: 'mobileSearch',
    });
  };

  return (
    <>
      <BottomSheetDrawer
        open={open}
        close={close}>
        <>
          <div className=''
            tabIndex='-1'
            aria-labelledby='searchRecoveryHousing'
            aria-hidden='true' >
            <div className='border-0 pt-2'>
              <Text XX_large className='w-100 text-center'>
                <FontAwesomeIcon icon={['far', 'search']} className='' />
                &nbsp;&nbsp;Start Search
              </Text>
            </div>
            <div className='p-0'>
              <div className='mobile-main-search'>
                <div className='filter'>
                  <p className='filter-label'>
                    I am a...
                  </p>
                  <Dropdown
                    isClearable={true}
                    instanceId='mobileAudienceType'
                    options={rolesWithoutOther}
                    placeholder='Select your role'
                    centerPlaceholderText
                    desktopStyle={true}
                    className='dropdown-menu-font'
                    value={RolesData.find((element) => element.id === searchStateCopyForMobile.role) || ''}
                    updateSelection={(value) => {
                      setSearchStateCopyForMobile({ ...searchStateCopyForMobile, role: value ? value.id : '' });
                    }}
                  />
                </div>
                <div className='filter'>
                  <p className='filter-label'>
                    Searching for houses within...
                  </p>
                  <Dropdown
                    instanceId='mobileDistance'
                    isClearable={false}
                    options={SearchDistanceData}
                    placeholder='miles'
                    centerPlaceholderText
                    desktopStyle={true}
                    className="dropdown-menu-font"
                    value={SearchDistanceData.find((element) => element.id == searchStateCopyForMobile.radius) || ''}
                    updateSelection={(value) => {
                      setSearchStateCopyForMobile({ ...searchStateCopyForMobile, radius: value ? value.id : '' });
                    }}
                  />
                </div>
                <div className='filter'>
                  <p className='filter-label'>
                    In the following location...
                  </p>
                  <AutoComplete
                    centerPlaceholderText
                    componentState={searchStateCopyForMobile}
                    setComponentState={setSearchStateCopyForMobile}
                    setInvalidSearch={setInvalidSearch}
                  />
                </div>
                <div className='filter'>
                  <p className='filter-label'>
                    Searching for housing that serves...
                  </p>
                  <Dropdown
                    instanceId='mobileGender'
                    isClearable={true}
                    options={createOptions('gender')}
                    placeholder='Select population'
                    centerPlaceholderText
                    desktopStyle={true}
                    className='dropdown-menu-font'
                    value={createOptions('gender').find((element) =>
                      element.id === searchStateCopyForMobile.gender) || ''}
                    updateSelection={(value) => {
                      setSearchStateCopyForMobile({ ...searchStateCopyForMobile, gender: value ? value.id : '' });
                    }}
                  />
                </div>
                <div className="modal-footer border-0">
                  {invalidSearch &&
                    <ErrorText bgWhite='showBackground' className='p-2'>
                      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                      <span>If using a distance radius, you must also enter a city or zip code
                        and choose from the autocomplete dropdown options.</span>
                    </ErrorText>}
                  <Button
                    primary width='100%'
                    height='80px'
                    fontSize='16px'
                    radius='10px'
                    onClick={handleSearch}>
                    FIND HOUSES
                  </Button>
                </div>
              </div>
            </div>
          </div >
        </>
      </BottomSheetDrawer>
    </>
  );
}
