/* eslint-disable max-len */
import React, { useState } from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { useForm, useFormContext, Controller } from 'react-hook-form';
import classnames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '../../components';
import {
  Button,
  Text,
} from '../../theme';
import Strings from '../../AppData/Strings/CheckoutStrings';
import './CheckoutProducts.scss';
import { StateDropdown } from '../../components/shared/StateDropdown';
import { CheckoutProductDetail } from './CheckoutProductDetail';
import { ErrorText } from '../../theme/wrappers/ErrorText';
import { CloseButton } from '../../components';
import colors from '../../scss/color.module.scss';
const { inputlines } = colors;
import { RolesData } from '../../AppData/GetHelpConstants/Roles';

const createProductOrder = async (request) => {
  const { data: response } =
    await axios.post(`${process.env.REACT_APP_API_ROOT}/api/assets/products/order/`, request);

  return response;
};

export function CheckoutProducts ({ refCheckout, modal, orderedItems }) {
  const [submissionError, setSubmissionError] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const { register, handleSubmit, reset, formState: { errors }, control, setValue, getValues } = useForm();
  const { getValues: formContextGetValues, setValue: formContextSetValue } = useFormContext();

  const { mutate } = useMutation(createProductOrder, {
    onSuccess: () => {
      setSubmissionSuccess(true);
      setSubmissionError('');

      const modalAllItems = getValues();
      for (const key in modalAllItems) {
        setValue(`${key}.digital`, false);
        setValue(`${key}.hardCopy`, false);
        setValue(`${key}.quantity`, '');
      }

      const allItems = formContextGetValues();
      for (const key in allItems) {
        formContextSetValue(`${key}.digital`, false);
        formContextSetValue(`${key}.hardCopy`, false);
        formContextSetValue(`${key}.quantity`, '');
      }
    },
    onError: (error) => {
      if (error.response.status == '400') {
        const errMsg = error.response.data;
        if (errMsg.email) {
          setSubmissionError('Submission error: Invalid email address');
        } else {
          setSubmissionError('Submission error');
        }
      } else {
        setSubmissionError('Submission error');
      }
    },
  });

  const onSubmitOrder = (data) => {
    let request = {
      ...data.info,
      line_items: [],
    };
    request.state = data.info.state.label;
    request.role = data.info.role.label;

    for (const key in data) {
      if (key != 'info') {
        if (data[key].digital || data[key].hardCopy) {
          const line_item = {
            product: key,
            digital: data[key].digital,
            hard_copy: data[key].hardCopy,
            quantity: data[key].hardCopy ? data[key].quantity : 0,
          };
          request.line_items.push(line_item);
        }
      }
    }

    mutate(request);
  };

  const getField = (obj) => {
    const name = `info.${obj.name}`;

    switch (obj.type) {
      case 'input':

        return (
          <>
            <input
              {...register(name, { required: { value: obj.required, message: `${obj.label} is required` },
              })}
              id={obj.name}
              type='text'
              className='form-control'
              aria-describedby={obj.label}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) =>
                <ErrorText className='px-1'>
                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                  {message}
                </ErrorText>}>
            </ErrorMessage>
          </>);
      case 'zipCode':
        // eslint-disable-next-line no-case-declarations
        const zipRegex = /^\d{5}(?:[- ]?\d{4})?$/;

        return (
          <>
            <input
              {...register(name, { required: { value: obj.required, message: `${obj.label} is required` },
                pattern: {
                  value: zipRegex,
                  message: 'invalid zip code'},
              })}
              id={obj.name}
              type='text'
              className='form-control'
              aria-describedby={obj.label}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) =>
                <ErrorText className='px-1'>
                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                  {message}
                </ErrorText>}>
            </ErrorMessage>
          </>);
      case 'text-area':
        return (
          <textarea
            {...register(name)}
            className='form-control'
            id={name}
            rows='6'>
          </textarea>);
      case 'your-role':
        return (
          <Controller
            control={control}
            name={name}
            isClearable
            defaultValue={null}
            rules={{ required: { value: true, message: 'Role is required' } }}
            render={({ field: { onChange } }) => (
              <>
                <Dropdown
                  border={true}
                  isClearable={true}
                  instanceId='checkoutAudienceType'
                  options={RolesData}
                  placeholder='Select role...'
                  updateSelection={onChange}
                  desktopStyle={true}
                />
                <ErrorMessage
                  errors={errors}
                  name={name}
                  render={({ message }) =>
                    <ErrorText className='px-1'>
                      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                      {message}
                    </ErrorText>}>
                </ErrorMessage>
              </>)}
          />);
      case 'state':
        return (
          <Controller
            control={control}
            name={name}
            isClearable
            defaultValue={null}
            rules={{ required: { value: true, message: 'State is required' } }}
            render={({ field: { onChange } }) => (
              <>
                <StateDropdown
                  updateSelection={onChange}
                />
                <ErrorMessage
                  errors={errors}
                  name={name}
                  render={({ message }) =>
                    <ErrorText className='px-1'>
                      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                      {message}
                    </ErrorText>}>
                </ErrorMessage>
              </>)}
          />);
      case 'email':
        // eslint-disable-next-line no-case-declarations
        const emailRegex =
          // eslint-disable-next-line no-control-regex
          /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

        return (
          <>
            <input
              {...register(name, {
                required: { value: obj.required, message: `${obj.label} is required` },
                pattern: {
                  value: emailRegex,
                  message: 'invalid email address',
                },
              })}
              id={obj.name}
              type='text'
              className='form-control'
              aria-describedby={obj.label}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) =>
                <ErrorText className='px-1'>
                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                  {message}
                </ErrorText>}>
            </ErrorMessage>
          </>);
    }
  };

  return (
    <div className='modal fade'
      ref={refCheckout}
      tabIndex='-1'
      aria-labelledby='checkoutProducts'
      aria-hidden='true' >
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <div className='modal-header border-0 mx-4 mb-0'>
            <Text extra_large className='w-100 text-center'>
              <FontAwesomeIcon icon={['fal', 'shopping-cart']} />
              {Strings.CHECKOUT.ORDER}
            </Text>
            <CloseButton onClick={() => {
              setSubmissionSuccess(false);
              setSubmissionError('');
              reset();
              setValue('info.role', null);
              setValue('info.state', null);
              modal.hide();
            }} />
          </div>
          {submissionSuccess == true ?
            <div className='text-center'>
              <Text large>
                {Strings.CHECKOUT.SUCCESS_HEADER}
              </Text>
              <FontAwesomeIcon
                className='mt-5'
                icon={['fal', 'paper-plane']}
                size='7x'
                color={inputlines} />
              <Text className='mt-5'>
                {Strings.CHECKOUT.SUCCESS_MESSAGE}
              </Text>
              <Text className='mt-4'>
                {Strings.CHECKOUT.FOOTER1}
                <br></br>
                {Strings.CHECKOUT.FOOTER2}
              </Text>
              <Button
                className='my-5'
                onClick={() => {
                  setSubmissionSuccess(false);
                  setSubmissionError('');
                  reset();
                  setValue('info.role', null);
                  setValue('info.state', null);
                  modal.hide();
                }}
                primary
                width='229px'
                height='36px'
                large>
                {Strings.CHECKOUT.CLOSE}
              </Button>
            </div>
            :
            <>
              <Text large className='text-center'>
                {Strings.CHECKOUT.FILL_OUT}
              </Text>
              <form onSubmit={handleSubmit(onSubmitOrder)}>
                <div className='modal-body'>
                  {Strings.CHECKOUT.FIELDS.map(
                    (obj, index) =>
                      <div className='mb-3 row' key={`checkout-${index}`}>
                        <label
                          htmlFor={`info.${obj.name}`}
                          className={classnames(
                            'offset-sm-1',
                            'col-sm-3',
                            'col-form-label',
                            'checkout-label',
                            { 'required': obj.required })}>
                          {obj.label}
                        </label>
                        <div className='col-sm-7'>
                          {getField(obj)}
                        </div>
                      </div>)}
                  <div className='mb-3 row'>
                    <label
                      htmlFor='orderDetails'
                      className='offset-sm-1 col-sm-3 col-form-label checkout-label'>
                      {Strings.CHECKOUT.DETAILS.TITLE}
                    </label>
                    <div className='col-sm-7'>
                      {orderedItems.map(
                        (obj) =>
                          <CheckoutProductDetail key={`pd-${obj.id}`}
                            productDetail={obj}
                            checkoutRegister={register}
                            checkoutSetValue={setValue}
                          />)}
                    </div>
                  </div>
                </div>
                <div className='modal-footer border-0 mb-4 mx-4 justify-content-between'>
                  <Text className='w-100 pt-1 text-center'>
                    <p className='digital-product-error'>{submissionError}</p>
                    {Strings.CHECKOUT.FOOTER1}
                    <br></br>
                    {Strings.CHECKOUT.FOOTER2}
                  </Text>
                  <div className='w-100 pt-3 text-center'>
                    <Button
                      submit
                      primary
                      width='169px'
                      height='39px'>
                      {Strings.CHECKOUT.SUBMIT}
                    </Button>
                  </div>
                </div>
              </form>
            </>
          }
        </div>
      </div>
    </div >
  );
}
