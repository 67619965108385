import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far,
  faAngleDoubleRight,
  faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';

library.add(
  far,
  fas,
  fal,
  faAngleDoubleRight,
  faBadgeCheck,
);
