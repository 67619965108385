export const GenderConstants = {
  MEN_ONLY: 'MEN_ONLY',
  MEN_CHILDREN: 'MEN_CHILDREN',
  WOMEN_ONLY: 'WOMEN_ONLY',
  WOMEN_CHILDREN: 'WOMEN_CHILDREN',
  NEUTRAL: 'NEUTRAL',
};

export const GenderData = [
  {
    'id': GenderConstants.MEN_ONLY,
    'label': 'Men only',
    'icon': 'mars',
  },
  {
    'id': GenderConstants.MEN_CHILDREN,
    'label': 'Men w/ children',
    'icon': [
      'female',
      'baby',
    ],
  },
  {
    'id': GenderConstants.WOMEN_ONLY,
    'label': 'Women only',
    'icon': 'venus',
  },
  {
    'id': GenderConstants.WOMEN_CHILDREN,
    'label': 'Women w/ children',
    'icon': [
      'female',
      'baby',
    ],
  },
  {
    'id': GenderConstants.NEUTRAL,
    'label': 'Accepts All Genders',
    'icon': 'transgender',
  },
];
