import { useState, useEffect } from 'react';

const useGetUserLocation = () => {
  const [location, setUserLocation] = useState();

  const getCoordinates = () => {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  useEffect(() => {
    let locationSubscription = true;
    getCoordinates().then((position) => {
      locationSubscription &&
      setUserLocation(position.coords);
    }).catch((error) => {
      console.log(error);
      setUserLocation(0);
    });

    return () => {
      locationSubscription = false;
    };

  }, []);

  return location;
};

export {useGetUserLocation};
