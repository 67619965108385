import React from 'react';
import { Text, RHLink } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useMobile,
} from '../../utils/hooks';
import HDCarousel from '../HouseDetail/HDCarousel';
import Strings from '../../AppData/Strings/HousesStrings';
import './ListViewCard.scss';
import { FavoriteButton } from '../../components';
import { Link } from 'react-router-dom';
import { StatusIndicator } from '../../components/shared/StatusIndicator';
import { SmokingConstants, SmokingData } from '../../AppData/GetHelpConstants/Smoking';
import { ResidentsServedConstants } from '../../AppData/GetHelpConstants/ResidentsServed';
import Gender from '../HouseDetail/Gender';
import classNames from 'classnames';
import withHttp from '../../utils/withHttp';
import Certification from '../../components/shared/Certification';
import { DistanceLabel } from '../../components/shared/distanceLabel';
import colors from './../../scss/color.module.scss';
const { inputlines } = colors;

const singleItemCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export function ListViewCard ({ id, house }) {
  const { isLargeScreen } = useMobile();
  const isTransInclusive = ResidentsServedConstants.TRANS_INCLUSIVE.some((element) =>
    house.residentsServed.includes(element));

  let houseSmokingStatus;
  if (house.rulesPolicies.smokingPolicy) {
    const findHouseSmokingStatus = (element) => element.id == house.rulesPolicies.smokingPolicy;
    houseSmokingStatus = SmokingData.find(findHouseSmokingStatus);
  } else {
    houseSmokingStatus = SmokingData.find((element) => element.id == SmokingConstants.PT_TOBACCO_NOT_ALLOWED);
  }

  return (
    <div className='list-house-container row mx-2 my-4'>
      <div className='col-6 house-img-carousel px-0'>
        <HDCarousel
          houseInfo={house}
          responsive={singleItemCarousel} />
      </div>
      <div className='col no-underline flex-column pt-4 '>
        <div className='row h-100 mb-4 mx-1 px-0 d-flex justify-content-center'>
          <div className='row m-0 p-0'>
            <div className='col-xs-12 col-lg-9 p-0'>
              <div className='row mx-0 px-0'>
                <div className='row mx-0 px-0'>
                  <div className='d-flex pe-4 ps-0'>
                    <div className='ps-0'>
                      <FavoriteButton
                        domain={'House'}
                        favoriteKey={id}
                        defaultValue={true}
                        size={'2x'}
                        backgroundColor={'mediumgray'} />
                    </div>
                    <div className='flex-grow-1px-0'>
                      <Link
                        to={`houseDetail?id=${id}`}>
                        <Text
                          extra_large>
                          {house.name}
                        </Text>
                      </Link>
                    </div>
                  </div>
                </div>
                <Text gray className='mt-1 ps-0'>
                  {house.address.city && house.address.state &&
                    <DistanceLabel
                      house={house}
                    />
                  }
                </Text>
                <Text gray className='ps-0'>
                  {house.beds != null && house.beds != 0 &&
                    `${house.beds} beds`}
                  {house.baths != null && house.baths != 0 && house.beds != null && house.beds != 0 &&
                    ` \u2022 `}
                  {house.baths != null && house.baths != 0 &&
                    `${house.baths} baths`}
                </Text>
              </div>
            </div>
            {!isLargeScreen &&
              <div className='col-lg-3 p-0 text-end'>
                <StatusIndicator
                  status={house.status}
                  updatedAt={house.updatedAt}
                  type={'leftJustify'} />
              </div>
            }
          </div>
          <div className='row row-cols-xs-2 row-cols-lg-3 mx-0 px-0'>
            <div className='col ps-0'>
              <div className='d-flex justify-content-start'>
                <div className='align-self-center pe-2'>
                  {house.rent.rentFrom && house.rent.amountDue != null &&
                    <FontAwesomeIcon className='m-0' icon={['fas', 'dollar-sign']} size='lg' pull='right' />
                  }
                </div>
                <Text bold>
                  {house.rent.rentFrom && house.rent.rentFrom !== 0 &&
                    <>
                      From ${house.rent.rentFrom}/month
                    </>
                  }
                  {!isLargeScreen && house.rent.amountDue != null && house.rent.amountDue != 0 &&
                    <div>
                      ${house.rent.amountDue} {Strings.Houses.UPFRONT.text}
                    </div>
                  }
                </Text>
              </div>
              {!isLargeScreen && house.acceptInsurance &&
                <Text gray small className='ms-4'>
                  <FontAwesomeIcon icon={['far', 'check']} />
                  {Strings.Houses.ACCEPT_INSURENCE.text}
                </Text>
              }
              {!isLargeScreen && house.weeklyPaymentsAllowed &&
                <Text gray small className='ms-4'>
                  <FontAwesomeIcon icon={['far', 'check']} />
                  {Strings.Houses.WEEKLY_PAYMENTS_ALLOWED.text}
                </Text>
              }
            </div>
            {!isLargeScreen ?
              <>
                <div className='col'>
                  {house.genderType &&
                    <div className={!isTransInclusive ? 'mb-3' : ''}>
                      <Text bold>
                        <Gender
                          genderServed={house.genderType}
                          iconsSize={'sm'}
                        />
                      </Text>
                    </div>
                  }
                  {isTransInclusive &&
                    <Text gray small className={classNames(
                      'text-start',
                      'ms-4',
                      { 'mb-3': isTransInclusive })}>
                      <FontAwesomeIcon icon={['far', 'check']} />
                      Trans-inclusive
                    </Text>
                  }
                  {house.certifications.certs.length > 0 &&
                    <div className='text-start mb-3'>
                      <Text large style={{ display: 'inline' }}>
                        <Certification
                          houseInfo={house}
                          hideText={true}
                        />
                      </Text>
                    </div>
                  }
                  {houseSmokingStatus &&
                    <div className='text-start'>
                      <Text className={houseSmokingStatus.id === SmokingConstants.PT_TOBACCO_NOT_ALLOWED &&
                        'text-decoration-line-through'}>
                        <FontAwesomeIcon
                          icon={['fal', houseSmokingStatus.icon]}
                          color={inputlines}
                          size='lg'
                        />
                        {houseSmokingStatus.label}
                      </Text>
                    </div>
                  }
                </div>
                <div className='col'>
                  {house.phone &&
                    <div className='text-end mb-5'>
                      <FontAwesomeIcon icon={['fas', 'phone']} size='lg' />
                      <a
                        className='regular-link'
                        href={`tel:${house.phone}`}>
                        {house.phone}
                      </a>
                    </div>
                  }
                  {house.website &&
                    <div className='text-end'>
                      <FontAwesomeIcon icon={['fas', 'external-link-square']} size='lg' />
                      <a
                        className='regular-link'
                        href={withHttp(house.website)}
                        target="_blank"
                        rel="noreferrer noopener">
                        View website
                      </a>
                    </div>
                  }
                </div>
              </>
              :
              <div className='col mb-4'>
                {house.genderType &&
                  <Text bold>
                    <Gender
                      genderServed={house.genderType}
                      iconsSize={'sm'}
                    />
                  </Text>
                }
              </div>
            }
          </div>
          {!isLargeScreen &&
            <div className='row text-center d-flex align-self-end mb-3'>
              <RHLink
                className='mt-xl-4 regular-link'
                to={`houseDetail?id=${id}`}>
                More details &nbsp;
                <FontAwesomeIcon icon={['far', 'angle-double-right']} />
              </RHLink>
            </div>
          }
          {isLargeScreen &&
            <div className='col-lg-3 p-0 mb-4 text-end'>
              <StatusIndicator
                status={house.status}
                updatedAt={house.updatedAt}
                type={'leftJustify'}
                size={'small'}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}
