import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../theme';
import colors from './../../scss/color.module.scss';
import { useMobile } from '../../utils/hooks';
import { ResidentsServedData } from '../../AppData/GetHelpConstants/ResidentsServed';

export default function Resident ({ residentType }) {

  const { isMobile } = useMobile();
  const findResident = (element) => element.id == residentType;
  const resident = ResidentsServedData.find(findResident);

  return (
    <>
      {isMobile != undefined && resident != undefined &&
        <div className={`${isMobile ? 'col-4' : 'col-2 mx-1'} my-3 text-center`}>
          <Text headerfooterbg very_large >
            {resident.icon.map((icon, index) =>
              <FontAwesomeIcon
                key={icon + index}
                className="m-0"
                icon={['fal', icon]}
                color={colors.headerfooterbg} />,
            )}
          </Text>
          <Text textblack large>{resident.label}</Text>
        </div>
      }
    </>
  );
}
