import React from 'react';
import { useDistanceLabel } from '../../utils/hooks/useDistanceLabel';
import { useGetUserLocation } from '../../utils/hooks/useGetUserLocation';

export function DistanceLabel ({house}) {
  let userLocation = useGetUserLocation();
  const distance = useDistanceLabel(house.address, userLocation);

  return (
    <>
      <span className='fw-bold'>
        {`${house.address.city}, ${house.address.state} `}
      </span>
      <span> {distance}</span>
    </>
  );
}
