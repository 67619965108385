import { useState, useEffect } from 'react';
import { getDistance, convertDistance } from 'geolib';

const useDistanceLabel = (address, currentLocation) => {
  const [distLabel, setDistLabel] = useState('Loading');

  useEffect(() => {
    let locationSubscription = true;
    if (address != undefined) {
      if (address.privateAddress) {
        setDistLabel('\u2022 Address hidden for privacy');

      } else {
        if (currentLocation != undefined && currentLocation != 0) {
          new Promise((resolve) => {
            if (address.latitude && address.longitude) {
              let dist = getDistance(
                { latitude: currentLocation.latitude, longitude: currentLocation.longitude },
                { latitude: address.latitude, longitude: address.longitude },
              );
              dist = Math.round(convertDistance(dist, 'mi'));
              resolve(`\u2022 ${dist.toString()} miles from location`);

            } else {
              resolve('');
            }
          }).then((label) => {
            if (locationSubscription) {
              setDistLabel(label);
            }
          });
        } else {
          currentLocation == 0 && setDistLabel('');
        }
      }
    } else {
      setDistLabel('');
    }

    return () => {
      locationSubscription = false;
    };

  }, [address, currentLocation]);

  return distLabel;
};

export { useDistanceLabel };
