export const LanguageConstants = {
  LANG_ASL: 'LANG_ASL',
  LANG_ARABIC: 'LANG_ARABIC',
  LANG_CHINESE: 'LANG_CHINESE',
  LANG_ENGLISH: 'LANG_ENGLISH',
  LANG_FRENCH: 'LANG_FRENCH',
  LANG_GERMAN: 'LANG_GERMAN',
  LANG_KOREAN: 'LANG_KOREAN',
  LANG_RUSSIAN: 'LANG_RUSSIAN',
  LANG_SPANISH: 'LANG_SPANISH',
  LANG_TAGALOG: 'LANG_TAGALOG',
  LANG_VIETNAMESE: 'LANG_VIETNAMESE',
  LANG_OTHER: 'LANG_OTHER',
};

// Data list is alphabetical by label (language name) with Other at the end of the list. 
// If updating this list, make sure to keep it in alphabetical order.
export const LanguagesData = [
  {
    'id': 'LANG_ASL',
    'label': 'American Sign Language (ASL)',
    'service_id': 217,
  },
  {
    'id': LanguageConstants.LANG_ARABIC,
    'label': 'Arabic',
    'service_id': 707,
  },
  {
    'id': LanguageConstants.LANG_CHINESE,
    'label': 'Chinese',
    'service_id': 704,
  },
  {
    'id': LanguageConstants.LANG_ENGLISH,
    'label': 'English',
    'service_id': 218,
  },
  {
    'id': LanguageConstants.LANG_FRENCH,
    'label': 'French',
    'service_id': 708,
  },
  {
    'id': LanguageConstants.LANG_GERMAN,
    'label': 'German',
    'service_id': 710,
  },
  {
    'id': LanguageConstants.LANG_KOREAN,
    'label': 'Korean',
    'service_id': 709,
  },
  {
    'id': LanguageConstants.LANG_RUSSIAN,
    'label': 'Russian',
    'service_id': 703,
  },
  {
    'id': LanguageConstants.LANG_SPANISH,
    'label': 'Spanish',
    'service_id': 219,
  },
  {
    'id': LanguageConstants.LANG_TAGALOG,
    'label': 'Tagalog',
    'service_id': 705,
  },
  {
    'id': LanguageConstants.LANG_VIETNAMESE,
    'label': 'Vietnamese',
    'service_id': 706,
  },
  {
    'id': LanguageConstants.LANG_OTHER,
    'label': 'Other',
    'service_id': 221,
  },
];

export const languagesWithoutEnglish = LanguagesData.filter((language) => 
  language.id !== LanguageConstants.LANG_ENGLISH);
