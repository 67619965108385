import React from 'react';
import { Text } from '../../../theme';
import { Link } from 'react-router-dom';

export function FooterLinks (props) {

  function externalLink () {
    if (props.obj.link.includes('http')) {
      return <a
        className="text-decoration-none text-white"
        href={props.obj.link} target="_blank"
        rel="noreferrer">
        {props.obj.label}
      </a>;
    } else {
      return <Link
        className="text-decoration-none text-white"
        to={props.obj.link}>
        {props.obj.label}
      </Link>;

    }
  }

  return (
    <div>
      <Text white className='pb-2 my-2'>
        {externalLink()}
      </Text>
    </div>
  );
}
