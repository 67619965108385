import React from 'react';
import { Text } from '../../theme';
import CheckedList from './CheckedList';
import { ApplicationData } from '../../AppData/GetHelpConstants/Application';
import { useMobile } from '../../utils/hooks';
import { LinkButtonWithIcon } from '../../components';
import './HouseDetail.scss';

function LinkButtonWithIconPrimary ({label, link, icon}) {

  const { isMobile } = useMobile();

  const width = isMobile ? '100%' : '';
  const height = isMobile ? '62px' : '42px';
  const lineHeight = isMobile ? '50px' : '27px';
  const radius = '4px';
  const boxShadow = '0 0rem 0rem rgb(0 0 0 / 0%) !important';
  const iconPosition='left';

  return (
    <LinkButtonWithIcon
      type='primary'
      link={link}
      label={label}
      width={width}
      height={height}
      lineHeight={lineHeight}
      icon={icon}
      iconPosition={iconPosition}
      radius={radius}
      boxShadow={boxShadow}
      target='_blank'
    />
  );
}

function LinkButtonWithIconSecondary ({label, link, icon}) {

  const { isMobile } = useMobile();

  const width = isMobile ? '100%' : '';
  const height = isMobile ? '62px' : '42px';
  const lineHeight = isMobile ? '50px' : '27px';
  const radius = '4px';
  const boxShadow = '0 0rem 0rem rgb(0 0 0 / 0%) !important';
  const iconPosition='left';

  return (
    <LinkButtonWithIcon
      type='secondary'
      link={link}
      label={label}
      width={width}
      height={height}
      lineHeight={lineHeight}
      icon={icon}
      iconPosition={iconPosition}
      radius={radius}
      boxShadow={boxShadow}
      color='primary'
      borderColor='secondary'
    />
  );
}

export default function Application ({ houseInfo, houseID}) {

  const { isMobile } = useMobile();

  const documentUpload = houseInfo.applicationProcess.applicationDocument;
  const applicationLink = houseInfo.applicationProcess.applicationLink;

  return (
    <>
      <div className='row mt-3' id='applicationForm'>
        <Text XX_large bold className='mb-4'>
          Application Process
        </Text>
        <div className='col'>
          <CheckedList
            returnedValues={houseInfo.applicationProcess.applicationRequirements}
            comparisonList={ApplicationData}
            type={isMobile ? 'single' : 'inline'}
          />
          <Text large className='pt-1'>
            {houseInfo.applicationText}
          </Text>
          <div className={isMobile ? 'col-12 pt-5':'row pt-3'}>
            <div className={isMobile ? '':'col-6 d-flex justify-content-start pb-3'}>
              {documentUpload &&
                <div className={isMobile ? 'pb-5':''}>
                  <LinkButtonWithIconPrimary
                    label='Download application form'
                    link={{pathname:documentUpload}}
                    icon={['fas', 'download']}
                  />
                </div>}
              {applicationLink &&
                <div className={isMobile ? 'pb-5' : documentUpload ? 'ms-5' : ''}>
                  <LinkButtonWithIconPrimary
                    label='View application'
                    link={{pathname:applicationLink}}
                    icon={['far', 'external-link']}
                  />
                </div>}
            </div>
            <div className={isMobile ? 'pb-5' : 'col-6 d-flex justify-content-end pb-3'}>
              <LinkButtonWithIconSecondary
                label='View contact info'
                link={isMobile ?  `/houseDetail?id=${houseID}#mobileContactCard` :
                  `/houseDetail?id=${houseID}#contactInfoCard`}
                icon={['far', 'angle-double-up']}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
