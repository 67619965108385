import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import ResidentsServed from './ResidentsServed';
import HeaderSection from './HeaderSection';
import { Text } from '../../theme';
import ItemList from './ItemList';
import Rent from './Rent';
import { LanguagesData } from '../../AppData/GetHelpConstants/Languages';
import RulesAndPolicies from './RulesAndPolicies';
import ResidentRequirements from './ResidentRequirements';
import Transportation from './Transportation';
import ServicesAndPrograms from './ServicesAndPrograms';
import Application from './Application';
import BedTypes from './BedTypes';
import ContactInfoCard from './ContactInfoCard';
import { useMobile } from '../../utils/hooks';
import MobileHeaderSection from './MobileHeaderSection';
import './HouseDetail.scss';
import GoToTop from '../../utils/goToTop';
import createHouseDetailsModel from '../../utils/hooks/createHouseDetailsModel';
import { Loading } from '../../components';
import { tabTitle } from '../../utils/tabTitle';

export default function HouseDetail () {

  const { isMobile } = useMobile();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  let houseInfo = null;
  // eslint-disable-next-line
  const GETHELP_API = process.env.REACT_APP_GETHELP_API;
  tabTitle('House Details - Find Recovery Housing Now');

  const getHouseDetails = async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, endpoint] = queryKey;
    const { data } = await axios.get(`${GETHELP_API}/v2/facilities/${endpoint}`);

    return data;
  };

  const {
    isLoading: isHouseDetailsLoading,
    isSuccess: isAPISuccess,
    data: houseDetailsData } = useQuery([`houseDetails_${id}`, id], getHouseDetails);

  if (isAPISuccess) {
    houseInfo = createHouseDetailsModel(houseDetailsData, 'houseDetail');
  }

  return (
    <div className={`container ${isMobile ? 'px-4 mt-4' : 'mt-5'}`}>
      {isHouseDetailsLoading ?
        <Loading />
        :
        houseInfo ?
          <>
            <div className="col">
              {isMobile ?
                <MobileHeaderSection id={id} houseInfo={houseInfo} />
                :
                <HeaderSection id={id} houseInfo={houseInfo} />}
            </div>
            <div className={!isMobile ? 'd-none' : 'col-12 pb-3 border-bottom border-placeholder'}>
              <ContactInfoCard
                houseInfo={houseInfo}
                id={id}
              />
            </div>
            <div className="row">
              <div className="col col-sm-12 col-md-8 border-bottom border-placeholder">
                <ResidentsServed
                  residentsServed={houseInfo.population.residentsServed}
                  priorityPopulations={houseInfo.population.priorityPopulations}
                />
                {houseInfo.languages.length > 0 &&
                  <div className="row pb-5 border-bottom border-placeholder">
                    <Text XX_large bold className="mb-4">
                      Languages spoken
                    </Text>
                    {houseInfo.languages.map((language, index) =>
                      <ItemList
                        key={index}
                        item={language}
                        list={LanguagesData}
                        bullet={true}
                        singleCol={true}
                      />)}
                  </div>
                }
                <div className="row pb-5 border-bottom border-placeholder">
                  <RulesAndPolicies
                    houseInfo={houseInfo}
                  />
                </div>
                <div className="row pb-5 border-bottom border-placeholder">
                  <Rent
                    houseInfo={houseInfo}
                  />
                </div>
                <div className="row pb-5 border-placeholder">
                  <BedTypes
                    houseInfo={houseInfo}
                  />
                </div>
              </div>
              <div className={isMobile ? 'd-none' : 'col-4'}>
                <ContactInfoCard
                  houseInfo={houseInfo}
                  id={id}
                />
              </div>
            </div>
            <div className="row pb-5 border-bottom border-placeholder">
              <ResidentRequirements
                houseInfo={houseInfo}
              />
            </div>
            <div className="row pb-5 border-bottom border-placeholder">
              <Transportation
                houseInfo={houseInfo}
              />
            </div>
            <div className="row pb-5 border-bottom border-placeholder">
              <ServicesAndPrograms
                houseInfo={houseInfo}
              />
            </div>
            <Application
              houseInfo={houseInfo}
              houseID={id}
            />
            <GoToTop />
          </>
          :
          <>
            <Text large className='my-5'>
              An error has occurred. Please try reloading the page. If the problem persists please contact
              <span>
                <Link to='/techAssistance'>Technical Assistance</Link>
              </span>
            </Text>
          </>
      }
      <GoToTop />
    </div>
  );
}
